import React,{useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form,Spinner } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Button from '../../components/shared/UI/Button';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import Toastmsg from '../../components/shared/toastmsg';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import AlertDismissible from '../../components/shared/aleartmsg';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getMsg} from '../../actions/passwordAction'
const resrtpassword = yup.object().shape({
    userPassword:yup.string().required('Enter your password').min(6,"Enter 6-digit password")
  });

 const Resetpassword = (props) =>{
    let { id,token } = useParams();
    const history = useHistory();

    const [baderrmsg,setBaderrMsg] = useState(null);
    const [errmsg,setErrmsg] = useState(null);
    const [loader,setLoader] = useState(false);
    const [passwordform,setPasswordform] = useState(false);
    const [aleart,setAleart] = useState(null);
    const [aleartmsg,setAleartmsg] = useState(null);
    const [btncolor,Setbtncolor] = useState(false)

    const { register, handleSubmit,reset , formState:{ errors } } = useForm({
        resolver: yupResolver(resrtpassword)
      });
      const checkloginToke = async (id,token) => {
 
        //   ------- Api for get url otp and send to backend
     let user = {
         encryptId:id,
         randomCode:token
     }
     try{
      let res = await apiCall.create(apiEndPoint.checktoken,user)
      console.log('test token',res.data)
      if(res && res.data.status === "success"){
          console.log('final data')
          setPasswordform(true)
         // setLoader(false)
      }else if(res && res.data.status === "error"){
         // setLoader(false)
         props.getMsg(res.data.message)
        //  setErrmsg(res.data.message)
        //  setBaderrMsg('error')
            setPasswordform(false)
         history.push('/forgotpassword');
      }
     }catch(err){
         console.log('res99',err)
         props.getMsg('Bad request kindly chek email')
         setPasswordform(false)
         history.push('/forgotpassword');
         // setLoader(false)
        //  setErrmsg('Bad request kindly chek email')
        //  setBaderrMsg('error')
     }
   

     // ----------
   }
      useEffect(()=>{
          console.log('check ui',props.match.params.id,'++++++++',token)
        return () => {
          checkloginToke(id,token)
        };
      

        // ----------
      },[props.getMsg])


      const resetPass = async (data, e) =>{ 
        setErrmsg(null)
        setBaderrMsg(null)
        setLoader(true)
        Setbtncolor(true)
          console.log(data); 
   
         let user = {
             encryptId:id,
             password:data.userPassword
            }
        try{
         let res = await apiCall.create(apiEndPoint.updatepassword,user)
         console.log('password ch',res.data)
         if(res && res.data.status === "success"){
            setLoader(false)
            let alaerts = {
                alearttype:"success",
                msg:res.data.message
            }
            setAleart(true)
            setAleartmsg(alaerts)
            Setbtncolor(false)
            e.target.reset();
         }else if(res && res.data.status === "error"){
            setLoader(false)
            let alaerts = {
                alearttype:"danger",
                msg:res.data.message
            }
            setAleart(true)
            setAleartmsg(alaerts)
            Setbtncolor(false)
            e.target.reset();
         }
        }catch(err){
            console.log('res99',err)
            setLoader(false)
            setErrmsg('Bad request kindly chek email')
            setBaderrMsg('error')
            Setbtncolor(false)
            e.target.reset();
        }
        };

       
     return(
        <>
          {passwordform ? (''):( 
               <div className="container">
               <div className="d-flex justify-content-center align-items-center vh-100">
                   <div className="spinner-border" role="status">
                       <span className="sr-only">Loading...</span>
                   </div>
             </div>
           </div>
          )}
         
        {passwordform ? (
             <div className="reg_wrap">
                <AlertDismissible showaleart={aleart} aleartmsg={aleartmsg} />
                {baderrmsg ? (<Toastmsg type={baderrmsg} msg={errmsg} />):('')} 
                    <Form className="reg_form" onSubmit={handleSubmit(resetPass)}>
                        <h2>Reset your password</h2>
                        <span>Enter your new password and Proceed.</span>
                       
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                             <Form.Label className="form_label">Password</Form.Label>
                            <Form.Control type="text" placeholder="Enter new password" {...register("userPassword")} className={`forms_input ${errors.userPassword ? 'invalid' : 'forms_input_border'}`} />
                            <div className="Err-feedback" >
                                  <span>{errors.userPassword?.message}</span>
                            </div>
                        </Form.Group>
                        
                        <Button type="submit" loadcolor={btncolor} >
                            {loader ? (<Spinner animation="border" role="status" size="sm" className="m-2">
                             <span className="sr-only">Loading...</span>
                            </Spinner>):('')}
                            Proceed
                            </Button>
                         <p className="acc_mode_change">Already have an account? <Link to="/login">Login</Link>.</p>
                  </Form>
              </div>
        ):('')}
       
         </>
     )
 }

function mapStateToProps(state) {
    return {
      
    };
} 

 const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({getMsg: getMsg}, dispatch);
}
 export default connect(mapStateToProps,matchDispatchToProps)(Resetpassword);