import axios from "axios";
import authAxios from "./apiUrl";

//https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
//https://www.bezkoder.com/jwt-refresh-token-node-js/
////https://www.bezkoder.com/react-refresh-token/
//https://www.youtube.com/watch?v=mbsmsi7l3r4

const authPrivateAxios = authAxios;
authPrivateAxios.interceptors.request.use(function (config) {
	const accessToken = localStorage.getItem('Token');
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`
	}
	return config;
},
(error) => {
	return Promise.reject(error);
});
authPrivateAxios.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;
		if (err.response) { //originalConfig.url !== "/auth/signin" && 
			// Access Token was expired			
			if (err.response.status === 403 && !originalConfig._retry) {
				originalConfig._retry = true;
				const refreshToken = localStorage.getItem('refreshToken');
				if(refreshToken){
					console.log('refreshToken:',refreshToken);
					try {								
						const rs = await authPrivateAxios.post("/user/refreshtoken", {
							refreshToken: refreshToken,
						});
						if(rs.data.status === 'success' && rs.data.message === 'refreshTokenVerified'){
							const newToken = rs.data.newAccessToken;
							localStorage.setItem("Token", newToken);
							authPrivateAxios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
							return authPrivateAxios(originalConfig);							
						}else{
							localStorage.removeItem('Token');
							localStorage.removeItem('refreshToken');
							localStorage.removeItem('TEuserData');
							window.location.pathname = "/signin";
						}
					} catch (_error) {
						return Promise.reject(_error);
					}
				}else{
					localStorage.removeItem('Token');
					localStorage.removeItem('refreshToken');
					localStorage.removeItem('TEuserData');
					window.location.pathname = "/signin";
				}
			}
		}
		return Promise.reject(err);
	}
);
 
export default authPrivateAxios;