import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

 const Toastmsg = (props) => {
     useEffect(()=>{
        console.log('prop test',props.type);
        if(props.type !== ""){
            switch(props.type){
                case 'info':
                    return  toast.info(props.msg,{position:toast.POSITION.TOP_RIGHT});
                case 'warn':
                    return  toast.warn(props.msg,{position:toast.POSITION.TOP_RIGHT});    
                case 'error':
                    return  toast.error(props.msg,{position:toast.POSITION.TOP_RIGHT});
            }
        }
     },[props.type])

    // const notify = () => toast("Wow so easy !");

    return (
      <div>
        {/* <button onClick={notify}>Notify !</button> */}
        <ToastContainer />
      </div>
    );
  }

  export default Toastmsg;