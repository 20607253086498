import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form,Spinner } from 'react-bootstrap';
//import {Link} from 'react-router-dom';
import Button from '../../shared/UI/Button';
import AlertDismissible from '../../shared/aleartmsg';
import config from '../../../helpers/config.json';
import apiCall from '../../../apis/apiService';
import apiEndPoint from '../../../apis/apiEndPoints';

const schemaotp = yup.object().shape({
	Otp: yup.string().required("Whoops. You didn't enter a code.").matches(/^([0-9][0-9]*)$/,"Please enter numeric value").min(6,"Enter 6-digit otp number").max(6,"Enter 6-digit otp number"),
});

const VerifyOtp = (props) =>{
	
	const history = useHistory();
	//const [errmsg,setErrmsg] = useState(null);
	const [loader,setLoader] = useState(false);
	const [aleart,setAleart] = useState(null);
	const [aleartmsg,setAleartmsg] = useState(null);
	const [btnColor,setBtnColor] = useState(false)
	
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(schemaotp)
	});

	const otpSubmit = async (data) =>{
		
        setLoader(true);
        setBtnColor(true);
        setAleart(null);
        setAleartmsg(null);
		
		let userFrmInfo = {
			areYou:props.userFrmInfo.areYou,
			firstName:props.userFrmInfo.firstName,
			lastName:props.userFrmInfo.lastName,
			email:props.userFrmInfo.email,
			contactNo:props.userFrmInfo.contactNo,
			address:props.userFrmInfo.address,
			city:props.userFrmInfo.city,
			state:props.userFrmInfo.state,
			zipCode:props.userFrmInfo.zipCode,
			sentOtpNo:props.sentOtpNo,
			enteredOtp:data.Otp
		}		
        try{
			let res = await apiCall.postPublic(apiEndPoint.contractorSignupEntry,userFrmInfo)
			if(res && res.data.status === "success"){
				 history.push('/contractor/thankyou');
			}else if(res && res.data.status === "error"){				
				setAleart(true);
				setAleartmsg({ alearttype:"danger", msg:res.data.message });
			}
		}catch(err){
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:config.ERROR_MSGS.BAD_REQUEST });			
		}
		setBtnColor(false);
		setLoader(false);         
	};

	//const backresendOtp = (data) => {
		//props.changeotp(data);
	//}
 
	return(       		
	   <Form className="reg_form" onSubmit={handleSubmit(otpSubmit)}>
		   <h2>Email Sent!</h2>
		   <span>Please check your email. We've sent a verification code to {props.userFrmInfo.email}</span>
		   <AlertDismissible showaleart={aleart} aleartmsg={aleartmsg} />
		   <Form.Group className="mb-3" controlId="formBasicEmail">
		   <Form.Label className="form_label">6 digit verification code</Form.Label>
			   <Form.Control type="text" placeholder="Enter OTP" {...register("Otp")} className={`forms_input ${errors.Otp ? 'invalid' : 'forms_input_border'}`} defaultValue={props.sentOtpNo} />
			   <div className="Err-feedback" >
				  <span> {errors.Otp?.message}</span>
			   </div>
		   </Form.Group>                   
		   <Button type="submit" loadcolor={btnColor} disabledSts={loader} >
				{loader ? <Spinner animation="border" role="status" size="sm" className="m-2" />:'Verify'}
			</Button>
		   {/*<p className="acc_mode_change">If you don't receive a verification code, click <Link to="/signup">here</Link> to resend.</p>*/}
	   </Form>
	);
}
export default VerifyOtp;