import React from 'react'
import { Link } from 'react-router-dom'

export default function Ready(props) {
    return (
        <div className="ready_sec text-center mb-5">
            <h2>{props.titleHeading}</h2>
            <p>{props.titleInfo}</p>            
            <Link to={props.linkTo} className="btn gold_btn">{props.btn_text}</Link>
        </div>
    )
}
