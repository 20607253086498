import React,{useEffect,useState} from 'react';
import Helmet from "react-helmet"
import config from '../../helpers/config.json';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens'; 
import { Col, Container, Row, Badge, BadgeProps, Alert, Form, Spinner, Button } from 'react-bootstrap';
import InnerTitle from '../title/InnerTitle';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const profileFrm = yup.object().shape({
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
	Email: yup.string().required("Oops! You didn't enter an email.").email('Please enter a valid email.'),
    contactNo: yup.string().required('Contact number is required').matches(/^([0-9][0-9]*)$/,"Please enter numeric value").min(10,"Enter 10-digit contact number")
});

const Profile = () => {
	
	toast.configure();
	const logUser = serviceToken.getLoggedInUser();
	const userId = logUser.userId;
	const [errMsg,setErrMsg] = useState(null);
	const [loader,setLoader] = useState(false);
	
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(profileFrm)
	});
	
	const profileFrmSubmit = async (data) =>{
		setLoader(true);
		let userData = {
			userId: userId,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.Email,
			contactNo: data.contactNo
		}
		try{
			const res = await apiCall.create(apiEndPoint.profileUpdate, userData);
			if(res && res.data.status === "success"){
				localStorage.setItem('TEuserData',JSON.stringify(res.data.userData));
				toast.success(res.data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
			}else{
				toast.error(res.data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
			}
		}catch(error){
			console.log('catch error: ',error);
			setErrMsg(error);
		}
		setLoader(false);
	};
		
	return (
		<section className="inner_wrapper">	
			<Helmet>
				<title>Profile - {config.PROJECT_TITLE}</title>
			</Helmet>
			<Container>
				<Row>
					<Col>
						<InnerTitle
							subTitle="General Information"
							titleHeading="View/Update Profile"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
					<div className="mb-5">
						<Form className="reg_form" onSubmit={handleSubmit(profileFrmSubmit)}>
								<ul className="ques_list mb-3">
									<li className="ques_item" style={{ display: 'flow-root' }}>
									
										<Form.Group>
											<Form.Label className="form_label ctrl-lbl">First Name *</Form.Label>
										   <Form.Control type="text" placeholder="" {...register("firstName")}  className={`forms_input ${errors.firstName ? 'invalid' : 'forms_input_border'}`}  defaultValue={logUser.firstName} />
										   <div className="Err-feedback" >
												<span> {errors.firstName?.message}</span>
										   </div>
										</Form.Group>
										
										<Form.Group>
											<Form.Label className="form_label ctrl-lbl">Last Name *</Form.Label>
											   <Form.Control type="text" placeholder="" {...register("lastName")}  className={`forms_input ${errors.lastName ? 'invalid' : 'forms_input_border'}`} defaultValue={logUser.lastName} />
											   <div className="Err-feedback" >
											   <span>{errors.lastName?.message}</span>
											   </div>
										</Form.Group>
										
										<Form.Group>
											<Form.Label className="form_label ctrl-lbl">Email *</Form.Label>
											<Form.Control type="text" placeholder="" {...register("Email")}  className={`forms_input ${errors.Email ? 'invalid' : 'forms_input_border'}`} defaultValue={logUser.email} />
											<div className="Err-feedback">
												<span>{errors.Email?.message}</span>
											</div>
										</Form.Group>
										
										<Form.Group>
										   <Form.Label className="form_label ctrl-lbl">Contact # *</Form.Label>
										   <Form.Control type="text" placeholder="" {...register("contactNo")}  className={`forms_input ${errors.contactNo ? 'invalid' : 'forms_input_border'}`} defaultValue={logUser.contactNo} />
										   <div className="Err-feedback" >
										   <span>{errors.contactNo?.message}</span>
										   </div>
									   </Form.Group>
									   
									</li>									
								</ul>
								<div className="btns_grp">
									<Button type="submit" className="btn gold_btn" style={{ width: '100%' }} disabled={loader}>
										{loader ? <Spinner animation="border" role="status" size="sm" className="" /> :'Update'}
									</Button>
								</div>
							</Form>
					</div>
					</Col>
				</Row>
			</Container>			
		</section>
	);
}

export default Profile