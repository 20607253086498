import React,{useEffect,useState} from 'react';
import { useLocation } from "react-router-dom";
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens';
import { fetchAllCMSAction } from '../../actions/cmsAction';
//import { fetchLiveTalkShowEventAction } from '../../actions/liveShowAction';
import { fetchUpcomingEventsAction } from '../../actions/eventAction';
import { fetchBannersTalkShowPageAction } from '../../actions/bannersTSAction';
import { fetchWorkerTypeAction } from '../../actions/workerTypeAction';
import { useDispatch, connect } from 'react-redux';
import '../css/lp_main.css';
import '../../assets/css/media.css';
import Header from './header';
import Footer from './footer';
import App from '../../App';

const Layout = (props) => {
	const {pathname} = useLocation();
	const pageName = pathname.split('/')[1];
	
	const dispatch = useDispatch();
	const [homeData,setHomeData] = useState(props.oncAllCMSData);
	const logUser = serviceToken.getLoggedInUser();
	if(logUser){
		var userId = logUser.userId;
	}else{
		var userId = 0;
	}
	//const [liveEventData, setLiveEventData] = useState(props.curLiveShowEvents);
	const [upcomingEventsData,setUpcomingEventsData] = useState(props.curUpcomingEvents);
	const [bannerTSData, setBannerTSData] = useState(props.curTSbanners);
	const [masterWorkerTypes,setMasterWorkerTypes] = useState(props.myWorkerTypeMasters);
  	
	async function fetchHomeData(){
		try{
			if(homeData.length===0){
				var response = await apiCall.getPublic(apiEndPoint.allCMSData);				
				setHomeData(response.data);
				dispatch(fetchAllCMSAction(response.data));
			}
			if(masterWorkerTypes.length===0){
				const mswtRes = await apiCall.getPublic('master/worker-types');
				dispatch(fetchWorkerTypeAction(mswtRes.data));
				setMasterWorkerTypes(mswtRes.data);	
			}
			/*if (liveEventData.length === 0 && userId>0) {
				const resEvent = await apiCall.get(apiEndPoint.liveEventDetails + userId);
				setLiveEventData(resEvent.data);
				dispatch(fetchLiveTalkShowEventAction(resEvent.data));
			}*/
			if(upcomingEventsData.length===0 && userId>0){
				const resEvent = await apiCall.getPublic(apiEndPoint.upcomingEvents+userId); // Calling Get API for getting upcoming events
				setUpcomingEventsData(resEvent.data);
				dispatch(fetchUpcomingEventsAction(resEvent.data));
			}
			if (bannerTSData.length === 0 && userId>0) {
				const resBanners = await apiCall.getPublic(apiEndPoint.advertiseBannerOnTalkShow);
				setBannerTSData(resBanners.data);
				dispatch(fetchBannersTalkShowPageAction(resBanners.data));
			}
		}catch(err){
			//setHomeDataLoading(false)
		}
	}
	
	useEffect(()=>{		
		fetchHomeData();
	},[]);
	
  	//console.log('Layout Calling....'+pageName);
	if(pageName==='signin' || pageName==='signup' || pageName==='forgot-password' || pageName==='via-referral'){
		return (<section className="te_main_lpage"><App /></section>);
	}
	
	return (
		<section className="te_main_lpage">
			<Header curPageName={pageName} />
			<App prsAllCmsData={homeData} />
			<Footer curPageName={pageName} contactDets={homeData} />
		</section>    
	);
};

//export default Layout;
const mapStateToProps = (state) => {
	//return { oncAllCMSData: state.stCMSData, curLiveShowEvents: state.stLiveTalkShowEvent, curTSbanners: state.stTSbanners, myWorkerTypeMasters: state.stWorkerTypesList };
	return { oncAllCMSData: state.stCMSData, curUpcomingEvents: state.stUpcomingEvents, curTSbanners: state.stTSbanners, myWorkerTypeMasters: state.stWorkerTypesList };
}

export default connect(mapStateToProps)(Layout) ; 