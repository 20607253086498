const apiEndPoint = {	
	allCMSData:"cms/front/page/home/all-sections-data",
	liveEventDetails:"events/front/live_talk_show/",
	upcomingEvents:"events/front/upcoming/",
	singleEventDetailsInArr:"events/single_events_details_arr/",
	advertiseBannerOnTalkShow:"advertise/banners/talk-show-page",
	verificationCodeSentOnEmail:"service/worker/verification",
	contractorSignupEntry:"service/worker/signup",
	findLocalPros:"service/workers/providers",
	searchProviders:"service/workers/search-providers",
	referralInviteFriends:"referral/friends/invites",
	quesRequest:"questions/frontend/asked_ques",
	fetchQuesCatsWithQues:"questions/frontend/data", // for send email 
	fetchUsersAskedQuesList:"questions/frontend/users/my_asked_ques/",
	fetchAskedQuesDetails:"questions/asked/details/",
	fetchAskedQuesDetailsByEncryptId:"questions/asked/details_by_encrypt_id/",
	scheduleUserAppointment:"questions/frontend/schedule/appointment",
	subCustYourFeedback:"feedback/create",
	profileUpdate:"user/profile/update", // update profile	
	passwordUpdate:"user/password/update", // update profile	
	registercheck:"user/check", // for send email 
	registerverify:"user/verify",// for verify otp 
	rgisteruser:"user/create", // for register user
	userCheckLogin:"user/check/login", //for login 
	resetemail:"user/forgot/password", // for resetpassword email send
	checktoken:"user/set/password/valid/link", // check for receive token by user
	updatepassword:"user/set/password", //change new password
}
export default apiEndPoint;