import React,{useEffect,useState} from 'react';
import Helmet from "react-helmet"
import config from '../../helpers/config.json';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens'; 
import { Col, Container, Row, Badge, BadgeProps, Alert, Form, Spinner, Button } from 'react-bootstrap';
import InnerTitle from '../title/InnerTitle';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const profileFrm = yup.object().shape({
    oldPassword: yup.string().required('Old password is required!'),
    newPassword: yup.string().required('New Password is required!').min(6, 'Password must be at least 6 characters!'),
	confirmPassword: yup.string().required('Confirm Password is required!').oneOf([yup.ref('newPassword')], 'Passwords must match!')
});

const AccountSetting = () => {
	
	const logUser = serviceToken.getLoggedInUser();
	const userId = logUser.userId;
	const [errMsg,setErrMsg] = useState(null);
	const [loader,setLoader] = useState(false);
	
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(profileFrm)
	});
	toast.configure();
	const profileFrmSubmit = async (data) =>{
		setLoader(true);
		let userData = {
			userId: userId,
			oldPassword: data.oldPassword,
			newPassword: data.newPassword,
			confirmPassword: data.confirmPassword
		}
		try{
			const res = await apiCall.create(apiEndPoint.passwordUpdate, userData);
			if(res && res.data.status === "success"){
				toast.success(res.data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000});
			}else{
				toast.error(res.data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000});
			}			 
		}catch(error){
			setErrMsg(error);
		}
		setLoader(false);
	};
		
	return (
		<section className="inner_wrapper">	
			<Helmet>
				<title>Account Setting - {config.PROJECT_TITLE}</title>
			</Helmet>
			<Container>
				<Row>
					<Col>
						<InnerTitle
							subTitle="Account Setting"
							titleHeading="Change your password"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
					<div className="mb-5">
						<Form className="reg_form" onSubmit={handleSubmit(profileFrmSubmit)}>
								<ul className="ques_list mb-3">
									<li className="ques_item" style={{ display: 'flow-root' }}>
									
										<Form.Group>
											<Form.Label className="form_label ctrl-lbl">Old Password *</Form.Label>
										   <Form.Control type="password" placeholder="" {...register("oldPassword")}  className={`forms_input ${errors.oldPassword ? 'invalid' : 'forms_input_border'}`} />
										   <div className="Err-feedback" >
												<span> {errors.oldPassword?.message}</span>
										   </div>
										</Form.Group>
										
										<Form.Group>
											<Form.Label className="form_label ctrl-lbl">New Password *</Form.Label>
											   <Form.Control type="password" placeholder="" {...register("newPassword")}  className={`forms_input ${errors.newPassword ? 'invalid' : 'forms_input_border'}`} />
											   <div className="Err-feedback" >
											   <span>{errors.newPassword?.message}</span>
											   </div>
										</Form.Group>
										
										<Form.Group>
											<Form.Label className="form_label ctrl-lbl">Confirm Password *</Form.Label>
											<Form.Control type="password" placeholder="" {...register("confirmPassword")}  className={`forms_input ${errors.confirmPassword ? 'invalid' : 'forms_input_border'}`} />
											<div className="Err-feedback">
												<span>{errors.confirmPassword?.message}</span>
											</div>
										</Form.Group>
									   
									</li>									
								</ul>
								<div className="btns_grp">
									<Button type="submit" className="btn gold_btn" style={{ width: '100%' }} disabled={loader}>
										{loader ? <Spinner animation="border" role="status" size="sm" className="" /> :'Update'}
									</Button>
								</div>
							</Form>
					</div>
					</Col>
				</Row>
			</Container>			
		</section>
	);
}

export default AccountSetting