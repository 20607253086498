import React from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/TE_full_logo_w.png';
import {FaLinkedinIn, FaYoutube,FaEnvelope, FaPhoneAlt} from 'react-icons/fa';
import configData from "../../helpers/config.json";
import { HashLink } from 'react-router-hash-link';
	
const Footer = (props) => {
	
	const cInfo = props.contactDets;
	const contactUsData = cInfo.filter(cInfo => cInfo.cmsId === 17);
	if(contactUsData.length===0){
		var contactEmail = '';
		var contactPhone = '';
		var linkedinLnk = '';
		var youtubeLnk = '';
	}else{
		var contactEmail = contactUsData[0].secTitle;
		var contactPhone = contactUsData[0].secSubTitle;
		var linkedinLnk = contactUsData[0].titleOne;
		var youtubeLnk = contactUsData[0].titleOneSlug;
	}
	
	const pageName = props.curPageName;
	const userAuthToken = localStorage.getItem('Token');
	
	const curYear = new Date().getFullYear();
	const footerText = curYear +' '+configData.PROJECT_TITLE+', All Rights Reserved.';
	
    return (
		<>
        {userAuthToken && pageName!=='' ?
			<footer className="lp_footer bgDark ">
				<Container>
					<Row>
						<Col md={12} className="text-center pt-3 pb-3">
							<span className="foot_copy">© {footerText}</span>
						</Col>
					</Row>
				</Container>
			</footer>			
		:
			<footer className="lp_footer bgDark pt-5 pb-4 ">
				<Container>
					  
					<Row>
						<Col md={4}>
							<div className="foot_logo">
								<HashLink to="/#"><img className="img-fluid" src={Logo} alt={configData.PROJECT_TITLE} /></HashLink>
								<p className='foot_p'>Subsidiary of Hardwick Engineering, LLC</p>
							</div>
							<ul className="social_links">
								<li><a target="_blank" href={linkedinLnk}><FaLinkedinIn /></a></li>
								<li><a target="_blank" href={youtubeLnk}><FaYoutube/></a></li>
							</ul>
						</Col>
						<Col md={4} sm={6}>
							<div className="foot_inner_wrap">
								<strong>Quick Links</strong>
								<ul className="foot_links">
									<li><HashLink to="/#">Home</HashLink></li>
									<li><Link to="/privacy-policy">Privacy Policy</Link></li>
 									<li><HashLink to="/#reviews">Reviews</HashLink></li>
									{/*<li><Link to="/contact-us">Contact</Link></li>*/}
									<li><Link to="/terms-conditions">Terms & Conditions</Link></li>									
								</ul>
							</div>
						</Col>
						<Col md={4} sm={6}>
							<div className="foot_inner_wrap">
								<strong>Let's Connect</strong>
								<ul className="foot_links foot_info">
									<li><Link to="/"><FaEnvelope/> {contactEmail}</Link></li>
									<li><Link to="/"><FaPhoneAlt/> {contactPhone}</Link></li>
								</ul>
							</div>
						</Col>
					</Row>
					 
					<Row>
						<Col md={12} className="text-center mt-3 pt-3">
							<span className="foot_copy">© {footerText}</span>
						</Col>
					</Row>
				</Container>
			</footer>
		}
		</>
    )
}

export default Footer;