import React,{useEffect,useRef} from 'react';
import styles from './Button.module.css'

export default function Button(props) {
    const btnref = useRef()
    useEffect(()=>{
        btnref.current.disabled = props.disabledSts; 
        if(props.loadcolor){
            btnref.current.style.backgroundColor = "#191970bf";
        }else{
        	btnref.current.style.backgroundColor = "";
        }
       },[props.loadcolor, props.disabledSts])

    return (
        <button type={props.type} ref={btnref} className={styles.button} disabled>
            {props.children}
        </button>
    )
}
