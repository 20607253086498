import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, connect } from 'react-redux';
import Helmet from "react-helmet";
import config from '../../helpers/config.json';
import GetProperDate from '../../helpers/GetProperDate';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens'; 
//import { fetchLiveTalkShowEventAction } from '../../actions/liveShowAction';
import { fetchUpcomingEventsAction } from '../../actions/eventAction';
import { fetchBannersTalkShowPageAction } from '../../actions/bannersTSAction';
import InnerTitle from '../../components/title/InnerTitle';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import BnrSquare from '../../assets/images/large_rectangle.png';
import BnrRect from '../../assets/images/bnr_rect.jpg';

const LiveEventInfo = (props) => {

	window.scrollTo(0, 0);
	const dispatch = useDispatch();
	const pageSlug = props.pageSlug;
	const [comPageContent] = useState(props.pageData);

	const logUser = serviceToken.getLoggedInUser();
	if(logUser){
		var userId = logUser.userId;
	}else{
		var userId = 0;
	}
	
	//const [liveEventData, setLiveEventData] = useState(props.curLiveShowEvents);
	const [upcomingEventsData,setUpcomingEventsData] = useState(props.curUpcomingEvents);
	const [bannerTSData, setBannerTSData] = useState(props.curTSbanners);
	const [tsLoaded, setTSLoaded] = useState(false);
	
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug","Sep", "Oct", "Nov", "Dec"];
	
	function shuffleArray(array) {
		let i = array.length - 1;
		for (; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
		return array;
	}

	async function fetchLiveEventsData() {
		try {
			setTSLoaded(false);
			/*if (liveEventData.length === 0) {
				const resEvent = await apiCall.get(apiEndPoint.liveEventDetails + userId);
				setLiveEventData(resEvent.data);
				dispatch(fetchLiveTalkShowEventAction(resEvent.data));
			}*/
			if(upcomingEventsData.length===0){
				const resEvent = await apiCall.getPublic(apiEndPoint.upcomingEvents+userId); // Calling Get API for getting upcoming events
				console.log('upcoming event: ',resEvent.data);
				setUpcomingEventsData(resEvent.data);
				dispatch(fetchUpcomingEventsAction(resEvent.data));
			}
			if (bannerTSData.length === 0) {
				const resBanners = await apiCall.getPublic(apiEndPoint.advertiseBannerOnTalkShow);
				setBannerTSData(resBanners.data);
				dispatch(fetchBannersTalkShowPageAction(resBanners.data));
			}else{				
				const allBanners = props.curTSbanners;
				const shuffledBanners = shuffleArray(allBanners);
				setBannerTSData(shuffledBanners);
			}
			setTSLoaded(true);
			//console.log('bannerTSData: ',bannerTSData);
		} catch (error) {
			console.log('catch error on Talk Show Page: ', error);
		}
	}

	useEffect(() => {
		fetchLiveEventsData();
	}, []);

	return (
		<>
			{comPageContent.filter(comPageContent => comPageContent.pageName === pageSlug).map(c => (
				<section key={c.cmsId} className="inner_wrapper pb-5 comPage">
					<Helmet>
						<title>{c.secTitle + ' - ' + config.PROJECT_TITLE}</title>
					</Helmet>
					<Container className="comPageCon">
						<Row>
							<Col md={12}>
								<InnerTitle
									subTitle=""
									titleHeading={c.secTitle}
								/>

							</Col>
						</Row>
						<Row>
							<Col md={9}>
								<div className="comPageInfo mt-4">
									<div dangerouslySetInnerHTML={{ __html: c.contentDescOne }} />
								</div>
							{tsLoaded ? (
								<>
								{upcomingEventsData ? (
								<>
								{upcomingEventsData.length>0 && upcomingEventsData.map((liveEventData, i) => (
								<div className="event_item live_event_item te_card my-3 p-3 mx-auto shadow-sm" key={liveEventData.eventId}>
									<div className="d-flex justify-content-between align-items-center">
									<div className="event_date"> 
										<span className="ms-1"><GetProperDate fDate={liveEventData.eventDateTimeStr} /></span>
									</div>
									{/*<div className="live_indi"><Spinner animation="grow" variant="danger" /> Live</div>*/}
									</div>
									<div className="event_title">
										<div className="mb-2"><small className="me-2">Topic:</small><span>{liveEventData.eventTopic.topicName}</span></div>
										<p>{liveEventData.eventTitle}</p>
									</div>
									<ul className="event_info">
										<li><small>Host:</small><span>{liveEventData.hostBy}</span></li>
										<li><small>Price:</small><span>{liveEventData.joinSts===0 ? 'Free' : <>${liveEventData.joinFee}.00</>}</span></li>
										<li><small>Duration:</small><span>{liveEventData.eventDuration+' Min.'}</span></li>
										<li><small>Live:</small><span>{liveEventData.liveShowSts===0 ? 'No' : 'Yes' }</span></li>
										{userId==0 ? 
											<li><Link className="btn gold_btn" to="/signin">Join Now!</Link></li>
										:
											<>
												{liveEventData.PaymentInfos.length===0 && liveEventData.joinFee>0 ?
													<li><Link className="btn gold_btn" to={'/payment/event/'+liveEventData.encryptEventId}>Pay Now!</Link></li>
												:
													<>
														{liveEventData.liveShowSts===1 ? <li><a className="btn gold_btn" target="_blank" href={liveEventData.eventURL}>Join Now!</a></li> : '' }
													</>
												}
											
											</>
										}
									</ul>
									</div>
									))}
									</>
								) : (
									<Spinner animation="grow" variant="danger" />
								)}
								</>
							) : (
									<Spinner animation="border" role="status" size="sm" className="m-2" />
							)}
							</Col>
							<Col md={3}>
								{tsLoaded ? (
									<Card>
										<Card.Body>
											<div className="sidebar">											
												<div className="bnrs_grp">
													{bannerTSData.length>0 && bannerTSData.map(b => (
													 <div className="bnr_squre mt-2" key={b.advertiseId}>
														<img className="img-fluid" src={config.SITE_URL+'admin/banners/'+b.bannerImg} alt={b.advertiseTitle} />
													</div>
													))}
												</div>
											</div>
										</Card.Body>
									</Card>
								) : (
									<></>
								)}
							</Col>
						</Row>
						<Row>
							<Col md={12}>

							</Col>
						</Row>
					</Container>
				</section>
			))}
		</>
	);
};

//export default ComPage

const mapStateToProps = (state) => {
	//return { curLiveShowEvents: state.stLiveTalkShowEvent, curTSbanners: state.stTSbanners };
	return { curUpcomingEvents: state.stUpcomingEvents, curTSbanners: state.stTSbanners };
}

export default connect(mapStateToProps)(LiveEventInfo);