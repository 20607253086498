import React,{useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form,Spinner } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Button from '../../components/shared/UI/Button';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import Toastmsg from '../../components/shared/toastmsg';
import AlertDismissible from '../../components/shared/aleartmsg';
import {connect} from 'react-redux';
import Logo from '../../landingPage/images/TE_icon_logo.svg';

	const schemaemail = yup.object().shape({
		Email: yup.string().required("Whoops. You didn't enter an email.").email('Please enter a valid email.'),
	});
	
	const Forgotpassword = (props) =>{
    const [baderrmsg,setBaderrMsg] = useState(null);
    const [errmsg,setErrmsg] = useState(null);
    const [aleart,setAleart] = useState(null);
    const [aleartmsg,setAleartmsg] = useState(null);
    const [loader,setLoader] = useState(false);
    const [btncolor,Setbtncolor] = useState(false)

    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schemaemail)
      });
      
      useEffect(()=>{
        
            if(props.tokenformerr !== null ){
                let alaerts = {
                    alearttype:"danger",
                    msg:props.tokenformerr
                }
                setAleartmsg(alaerts)
                setAleart(true)
                console.log('receive in component',props.tokenformerr)
            }
      },[props.tokenformerr] )

      const emailForgot = async (data,e) =>{ 
			setErrmsg(null)
			setBaderrMsg(null)
			setLoader(true)
			setAleart(null)
			Setbtncolor(true)
			console.log(data); 
			
			let user = {email:data.Email}
        try{
         let res = await apiCall.create(apiEndPoint.resetemail,user)
         if(res && res.data.status === "success"){
            let alaerts = {
                alearttype:"success",
                msg:res.data.message
            }
            setAleartmsg(alaerts)
            setAleart(true)
            setLoader(false)
            Setbtncolor(false)
            e.target.reset();
         }else if(res && res.data.status === "error"){
            setLoader(false)
            // setErrmsg(res.data.message)
            let alaerts = {
                alearttype:"danger",
                msg:res.data.message
            }
            setAleartmsg(alaerts)
            // setBaderrMsg('error')
            setAleart(true)
            Setbtncolor(false)
         }
        }catch(err){
            setLoader(false)
            Setbtncolor(false)
            setErrmsg('Bad request kindly chek enternet connection')
            setBaderrMsg('error');
        }
     };
	 
	 const descLine = "Enter your email and we'll send you a link to reset your password.";
   
     return(
        <>
       <div className="reg_wrap"> 
            {baderrmsg ? (<Toastmsg type={baderrmsg} msg={errmsg} />):('')} 
                   <Form className="reg_form" onSubmit={handleSubmit(emailForgot)}>
				   	   <Link to="/"><img src={Logo} alt="" className="mb-4" /></Link>
                       <h2>Reset your password </h2>
                       <span>{descLine}</span>
                       
                       <Form.Group className="mb-3" controlId="formBasicEmail">
                           <Form.Label className="form_label">Email *</Form.Label>
                           <Form.Control type="text" placeholder="" {...register("Email")} className={`forms_input ${errors.Email ? 'invalid' : 'forms_input_border'}`} />
                           <div className="Err-feedback" >
                                <span>{errors.Email?.message}</span>
                           </div>
                       </Form.Group>
                       
                       <Button type="submit" loadcolor={btncolor}>
                           {loader ? (<Spinner animation="border" role="status" size="sm" className="m-2">
                            <span className="sr-only">Loading...</span>
                       </Spinner>):('')}
                           Proceed
                           </Button>
                        <p className="acc_mode_change">Already have an account? <Link to="/signin">Log in</Link>.</p>
                 </Form>
             </div>
        </>
     )
 }
 function mapStateToProps(state) {
    return {
        tokenformerr: state.formErrredux
    };
} 
 export default connect(mapStateToProps)(Forgotpassword);