import React,{useEffect,useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import Review from './Review';
import NickImg from '../../../assets/images/cr1.jpg'


export default function ReviewCarousel(props) {
	const [reviewData,setReviewData] = useState(props.reviewsData);
	
    const options = {
        margin: 15,
        responsiveClass: true,
        nav: true,
        loop: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        center:true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
                
        stagePadding:0,
            },
            700: {
                items: 2,
                
        stagePadding:50,
            },
            1000: {
                items: 3,
    
                stagePadding:150,
            }
        },
    };
    return (
        <div className="reviewCaro">   
        <OwlCarousel className="owl-theme"  {...options} >  
			{reviewData.map(tstm => (
				<div className="item">
					<Review
						review={tstm.shortDescOne}
						revName={tstm.titleOne}
						revDesi={tstm.titleTwo}
					/>
				</div>
			))}
        </OwlCarousel>         
    </div> 
    )
}
