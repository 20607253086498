import React from 'react';

export default function InnerTitle(props) {
    return (
        <div className="page_title">
            {props.subTitle ? <span>{props.subTitle}</span> : ''}
            {props.titleHeading ? <h2>{props.titleHeading}</h2> : ''}
            {props.titleInfo ? <p>{props.titleInfo}</p> : ''}
        </div>
    )
}