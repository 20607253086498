import React, {useState,useEffect,useRef} from 'react';
import { Link, useHistory } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Container,Dropdown,DropdownButton} from 'react-bootstrap';
import { BsWallet,BsPersonFill} from "react-icons/bs";
import Logo from '../images/TE_icon_logo.svg';
import UserImg from '../../assets/images/cr1.jpg';
import config from '../../helpers/config.json';
import serviceToken from '../../apis/serviceTokens';
import { HashLink } from 'react-router-hash-link';

export default function Header(props) {

    const pageName = props.curPageName;
	const history = useHistory();
	const userAuthToken = serviceToken.getSessionAccessToken();
	const logUser = serviceToken.getLoggedInUser();
	const [scroll, setScroll] = useState(false);
	const [userSessChk, setUserSessChk] = useState(userAuthToken);
	
	useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 0);
        });
    }, []);
	
	const handleSignout = () => {
		serviceToken.userSessionDistory();
		history.push('/signin');
	}
	
	const handleProfile = () => {
		history.push('/profile');
	}
	
	const handleAccountSetting = () => {
		history.push('/account-setting');
	}
	
	const handleReferaFriend = () => {
		history.push('/referral');
	}
	
	const handleDashboard = () => {
		history.push('/dash');
	}

    return (
        <>
            <Navbar expand="lg" className= {scroll ? "lp_menu fixed-top bg-white shadow" : "lp_menu fixed-top "}>
                <Container>
                    <Navbar.Brand>
                        <HashLink to="/#"><div className="te_logo"><img src={Logo} className="img-fluid" alt={config.SHORTCUT_PROJECT_TITLE} /><span>{config.SHORT_PROJECT_TITLE}</span></div></HashLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
						{userSessChk ?
						<>
							<Link className="nav-link" to="/dash">Dashboard</Link>
							<Link className="nav-link" to="/question/asked">Ask a Question</Link>
							<Link className="nav-link" to="/question/my">My Questions</Link>
							{/*<Link className="nav-link" to="/referral">Referral</Link>*/}
							<Link className="nav-link" to="/find-local-professionals">Find Local Pro's</Link>
							<Link className="nav-link" to="/talk-show">Talk Show</Link>
						</>
						:
						<>
							<HashLink className="nav-link" to="/#">Home</HashLink>
							<HashLink className="nav-link" to="/#services">Services</HashLink>
							<HashLink className="nav-link" to="/#howItWorks">How It Works</HashLink>
							<HashLink className="nav-link" to="/#aboutUs">About</HashLink>
							<Link className="nav-link" to="/find-local-professionals">Find Local Pro's</Link>
						</>
						}
                    </Nav>
                    <Nav className="ms-auto">
                        {userSessChk ?
							<div className={pageName=='' ? "user_head home-page" : "user_head"}>
								<div className="my_wallet"><BsWallet style={{ fontSize: '18px' }} /> $ {logUser.walletBal}</div>
								{/*<div className="uh_img shadow"><img src={UserImg} alt=""/></div>*/}
								<DropdownButton
								align="end"
								title={"Hi, "+logUser.firstName}
								id="dropdown-menu-align-right"
								variant="default"
								>
 									<Dropdown.Item eventKey="1" onClick={handleDashboard}>Dashboard</Dropdown.Item>
									<Dropdown.Item eventKey="2" onClick={handleReferaFriend}>Refer a Friend</Dropdown.Item>
									<Dropdown.Item eventKey="3" onClick={handleProfile}>Profile</Dropdown.Item>
									<Dropdown.Item eventKey="4" onClick={handleAccountSetting}>Account Setting</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item eventKey="5" onClick={handleSignout}>Sign-Out</Dropdown.Item>
								</DropdownButton>
							</div>
							:
							<div className="hd_btn_grp">
								<Link className="btn gold_btn" to="/talk-show">Talk Show</Link>
								<Link className="btn gold_btn" to="/signin"><BsPersonFill/></Link>
							</div>
						}
                    </Nav>					
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}