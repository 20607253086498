import React,{useEffect,useState,useRef} from 'react';
import Helmet from "react-helmet"
import config from '../../helpers/config.json';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row, Badge, BadgeProps } from 'react-bootstrap';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens';
import {IoChevronForwardCircleOutline,IoHelpCircleOutline} from 'react-icons/io5';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form, Spinner, Button } from 'react-bootstrap';
import InnerTitle from '../title/InnerTitle';
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

const Ask = () => {
  	const logUser = serviceToken.getLoggedInUser();
	const history = useHistory();
	const userId = logUser.userId;
	const defBtnLabel = 'Pay Now';
	const pageTitleLabel = 'Select Question Type';
	const [pageTitle,setPageTitle] = useState(pageTitleLabel);
	const [quesLoaded, setQuesLoaded] = useState(false);
	const [quesCatData,setQuesCatData] = useState([]);
	const [ftrQuesData,setFtrQuesData] = useState([]);
	const [ftrQuesSearchResultData,setFtrQuesSearchResultData] = useState([]);
	const [otherQuesSts,setOtherQuesSts] = useState(false);
	const [btnLabel,setBtnLabel] = useState(defBtnLabel);
	const [loader,setLoader] = useState(false);
	const [errMsg,setErrMsg] = useState(null);
	const [selectedQuesId,setSelectedQuesId] = useState('');
	const [selectedQuesAmt,setSelectedQuesAmt] = useState(0);
	
	const inputEI = useRef("");
	//const [searchTerm,setSearchTerm] = useState("");
	
	const [paymentSts,setPaymentSts] = useState(false);
	const [scheApptLoader,setScheApptLoader] = useState(false);
	
	const [dt, setDt] = useState(moment());	
	
	const updateSearch = () => {
		const searchTerm = inputEI.current.value;
		if(searchTerm !== ""){
			const newSearchedList = ftrQuesData.filter((quesData) => {
				 return Object.values(quesData).join(" ").toLowerCase().includes(searchTerm.toLowerCase());							
			});
			setFtrQuesSearchResultData(newSearchedList);
		}else{
			setFtrQuesSearchResultData(ftrQuesData);
		}
	}
	useEffect(()=>{	
   	},[ftrQuesSearchResultData]);
  
  	async function fetchQuesCatsData(){
		try{
			setQuesLoaded(false);
			const response = await apiCall.get(apiEndPoint.fetchQuesCatsWithQues); // Calling Get API of All Categories Data with questions			
			setQuesCatData(response.data);
			setQuesLoaded(true);
		}catch(error){
			setErrMsg(error);
		}
	}
	
	useEffect(()=>{		
		fetchQuesCatsData();
	},[]);
	
	async function fetchQuesListClick(selCatId){
		if(selCatId){
			const getFilteredQuesList = quesCatData.filter(quesCatData => quesCatData.catId === selCatId);
			setFtrQuesData(getFilteredQuesList[0].Questions);
			setFtrQuesSearchResultData(getFilteredQuesList[0].Questions);
			setPageTitle('Select Your Question');
		}
	}
	
	function backToCatListClick(){
		setSelectedQuesId(''); 
		setFtrQuesData([]);
		setPageTitle(pageTitleLabel);
	}
	
	function otherQuesClick(){
		setSelectedQuesId(''); 
		setOtherQuesSts(true);
		setBtnLabel('Send Request');
	}
	
	function selQuesClick(quesId,quesCost){
		setSelectedQuesId(quesId); 
		setSelectedQuesAmt(quesCost); 
		setOtherQuesSts(false);	
		setLoader(false);
		setBtnLabel(defBtnLabel);
	}
	
	async function payNowClick(){
		if(parseInt(selectedQuesId)>0){
			setLoader(true);
			let quesReqData = {
				userId:userId,
				quesId:selectedQuesId,
				otherQuesTitle:'',
				quesAmt:selectedQuesAmt
			}
			try{
				const res = await apiCall.create(apiEndPoint.quesRequest,quesReqData);
				if(res && res.data.status === "success"){
					history.push('/payment/question/'+res.data.encryptAskedQuesId);
				}else{
					setErrMsg(res.data.message);
				}
			}catch(error){
				setErrMsg(error);
			}
			setLoader(false);
			//setPageTitle('Book Your Consulting Slot');
			//setPaymentSts(true);
		}else{
			setErrMsg('Please select your Question.');	
		}
	}
	
	useEffect(()=>{
		if(ftrQuesData.length>0){ fetchQuesListClick(); }
	},[ftrQuesData, otherQuesSts]);
	
	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	
	const quesSubmit = async (data) =>{
		setLoader(true);
		let quesReqData = {
			userId:userId,
			quesId:0,
			otherQuesTitle:data.OtherQuesTxt,
			quesAmt:0
		}		
		try{
			const res = await apiCall.create(apiEndPoint.quesRequest,quesReqData);
			if(res && res.data.status === "success"){
				history.push('/question/thankyou');
			}else{
				setErrMsg(res.data.message);
			}
		}catch(error){
			setErrMsg(error);
		}
		setLoader(false);
	};
		
	return (
		<>
		<Helmet>
			<title>Ask a Question - {config.PROJECT_TITLE}</title>
		</Helmet>
		<section className="inner_wrapper">
			<Container>
				<Row>
					<Col>
						<InnerTitle
							subTitle="Ask a Question"
							titleHeading={pageTitle}
						/>
					</Col>
				</Row>
				{quesLoaded ? (
				<Row>
					<Col md={12}>
						<div className="ques_wrap">
						 
								<Form className="reg_form" onSubmit={handleSubmit(quesSubmit)}>
								{ftrQuesData.length>0 ?
								<>
									<Form.Group className="mb-3">
										<Form.Control placeholder="" placeholder="Search Question..." ref={inputEI} onChange={() => updateSearch()} className="forms_input_border" />
									</Form.Group>
									
									<ul className="ques_list">
										{ftrQuesSearchResultData.map(quesData => (
											<li key={quesData.quesId} className={(selectedQuesId === quesData.quesId ? "ques_item selected" : "ques_item")} onClick={() => selQuesClick(quesData.quesId,quesData.quesCost)}>
												<strong>Q. {quesData.quesTitle}</strong>
												<Badge bg="success" className="ques_price">${quesData.quesCost}</Badge>
											</li>
										))}
										
										{otherQuesSts ?
											<li className="ques_item">
												<Form.Group className="mb-0">
													<Form.Label className="form_label"><strong>Other Question *</strong></Form.Label>
													<Form.Control as="textarea" rows={6} placeholder="" {...register("OtherQuesTxt", { required: true })}  className={`${errors.OtherQuesTxt ? 'invalid' : 'forms_input_border'}`} />
													<div className="Err-feedback">
														<span>{errors.OtherQuesTxt && <span>Please enter your other question.</span>}</span>
													</div>
												</Form.Group>
											</li>
										:
											<li className="ques_item" onClick={() => otherQuesClick()}>
												<strong>Other</strong>
											</li>
										}
									</ul>
									<div className="btns_grp">
										<Button className="btn gold_btn" onClick={() => backToCatListClick()}>Back</Button>
										{otherQuesSts ?
											<Button type="submit" className="btn gold_btn" disabled={loader}>
												{loader ? <Spinner animation="border" role="status" size="sm" className="" />:'Send Request'}
											</Button>
										:
											<Button type="button" className="btn gold_btn" disabled={loader} onClick={() => payNowClick()}>
												{loader ? <Spinner animation="border" role="status" size="sm" className="" />:'Pay Now'}
											</Button>
										}
									</div>						
								</>
								: 
									<ul className="ques_list">
										{quesCatData.map(catQues => (
											<li className="ques_item" key={catQues.catId} onClick={() => fetchQuesListClick(catQues.catId)}>
												<strong>{catQues.catName}</strong>
												<IoChevronForwardCircleOutline fontSize="24px"/>
											</li>
										))}
									</ul>
								}
								</Form>
							 
						 
						</div>
					</Col>
				</Row>
				) : (
					<Row>
						<Col md={12}>
							<div className="ques_wrap">
								<h5 className="text-center">Please Wait <Spinner animation="border" role="status" size="sm" className="" /></h5>
							</div>
						</Col>
					</Row>
				)}
			</Container>
		</section>
		</>  
	);
};

export default Ask
