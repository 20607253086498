import React from 'react';

export default function PageTitle(props) {
    return (
        <div className="page_title">
            <span>{props.subTitle}</span>
            <h2>{props.titleHeading}</h2>
            <div dangerouslySetInnerHTML={{ __html: props.titleInfo }} />
        </div>
    )
}
