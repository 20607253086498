import React,{useEffect, useState} from 'react';
import { Alert } from 'react-bootstrap';

const AlertDismissible = (props) => {
	
	const [show, setShow] = useState(false);
	
	useEffect(()=>{
		setShow(props.showaleart)
	},[props.showaleart])
	
	if (show) {
		return (
			<Alert variant={props.aleartmsg.alearttype} >
				{props.aleartmsg.msg}
			</Alert>
		);
	}
	return ('');
}

export default AlertDismissible;