import React from 'react'

export default function HwCard(props) {
    return (
        <div className="hiw_card">
            <div className="hiw_img"><img src={props.hiwImg} alt=""/></div>
            <div>
				<h4>{props.hiwTtile}</h4>
				<p>{props.hiwDesc}</p>
            </div>
        </div>
    )
}
