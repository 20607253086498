import React from 'react'
import { Link } from 'react-router-dom';
import { BiCalendarCheck } from 'react-icons/bi';
import './Event.css';
import GetProperDate from '../../helpers/GetProperDate';

export default function Event(props) {	
	//const months = ["January", "February", "March", "April", "May", "June", "July", "August","September", "October", "November", "December"];
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug","Sep", "Oct", "Nov", "Dec"];
	var payLnk = '/payment/event/'+props.eInfo.encryptEventId;
	var eventURL = props.eInfo.eventURL;
	
	if(props.eInfo.joinSts===0){		
		var eFee = 'Free';
	}else{		
		var eFee = '$'+props.eInfo.joinFee;
	}
	
    return (
        <div className="event_item te_card my-3 p-3 mx-auto shadow-sm">
            <div className="event_date"><BiCalendarCheck /> <span className="ms-1"><GetProperDate fDate={props.eInfo.eventDateTimeStr} /></span></div>
            <div className="event_title">{props.eInfo.eventTitle}</div>
            <ul className="event_info">
                <li><small>Topic:</small><span>{props.eInfo.eventTopic.topicName}</span></li>
                <li><small>Host:</small><span>{props.eInfo.hostBy}</span></li>
                <li><small>Duration:</small><span>{props.eInfo.eventDuration+' Min.'}</span></li>
            </ul>
            <div className="event_foot">
                <span className="event_fees">{eFee}</span>
				{props.lpInd==0 ?
					<>
						{props.eInfo.joinSts===0 ?
							<Link to='/live-talk-show' className="btn gold_btn">Join Now!</Link>
						:
							<>
								{props.eInfo.PaymentInfos.length===0 ?
									<Link to={payLnk} className="btn gold_btn">Pay Now!</Link>
								:
									<Link to='/live-talk-show' className="btn gold_btn">Join Now!</Link>
								}
							</>
						}
					</>
				:
					<></>
				}
            </div>
        </div>
    )
}
