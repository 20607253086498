import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { FaCheck } from "react-icons/fa";
import {IconContext} from 'react-icons';

export default function LpService(props) {
	const ContentMetaDetails = props.serDetails.ContentMetaDetails;
    return (
        <div className="lp_services">
			<Row>
				<Col md={5} sm={12}>
					<div className="lps_intro">
						<h4>{props.serDetails.titleOne}</h4>
						<div dangerouslySetInnerHTML={{ __html: props.serDetails.contentDescOne }} />
					</div>
				</Col>
				<Col md={7} sm={12}>
					<div className="lps_features">
						<ul className="lps_features_list">
							{ContentMetaDetails.map(cmsMeta => (
							<li>
								<div className="icon_box">
								<IconContext.Provider value={{ className: 'icon_gold' }}>
									<FaCheck/>
								</IconContext.Provider>
								</div>
								<div className="fea_desc">
									<h5>{cmsMeta.mTitle}</h5>
									<p>{cmsMeta.mDesc}</p>
								</div>
							</li>
							))}
						</ul> 
					</div>
				</Col>
			</Row>
        </div>
    )
}
