class serviceToken {
  
	getSessionAccessToken(){ return localStorage.getItem("Token"); }
	
	getLocalRefreshToken(){ return localStorage.getItem("refreshToken"); }
	
	getLoggedInUser(){ return JSON.parse(localStorage.getItem("TEuserData")); }  
	
	userSessionDistory() {
		localStorage.removeItem('Token');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('TEuserData');
	}
   
  /*updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
  
  setUser(user) {
    console.log(JSON.stringify(user));
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
  }*/
}
export default new serviceToken();