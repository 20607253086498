import authAxios from "./apiUrl";
import authPrivateAxios from "./apiUrlPrivate";

class apiCall {
	
	async get(apiPath){
		try{			
			return await authPrivateAxios.get(apiPath);
		}catch(error){
			return error;
		}
	}
	
	async create(apiPath, data) {
		try{			
			return await authPrivateAxios.post(apiPath, data);
		}catch(error){
			return error;
		}
	}	
	
	/*async get(apiPath){
		try{
			authAxios.interceptors.request.use(function (config) {
				const accessToken = localStorage.getItem('Token');
				//console.log('accessToken: ',accessToken);
				config.headers.Authorization = `Bearer ${accessToken}`
				return config;
			});
			return await authAxios.get(apiPath);
		}catch(error){
			return error;
		}
	}
	
	async create(apiPath, data) {
		try{
			authAxios.interceptors.request.use(function (config) {
				const accessToken = localStorage.getItem('Token');
				config.headers.Authorization = `Bearer ${accessToken}`
				return config;
			});
			return await authAxios.post(apiPath, data);
		}catch(error){
			return error;
		}
	}*/
	
	async getPublic(apiPath){
		try{
			return await authAxios.get(apiPath);
		}catch(error){
			return error;
		}
	}
	
	async postPublic(apiPath, data) {
		try{
			return await authAxios.post(apiPath, data);
		}catch(error){
			return error;
		}
	}

	/*async get(apiPath,id){
		return await http.get(`${apiPath}${id}`);
	}
	
	async update(apiPath,id, data) {
		return await http.put(`${apiPath}${id}`, data);
	}
	
	async delete(apiPath,id) {
		return await http.delete(`${apiPath}${id}`);
	}
	
	async deleteAll(apiPath) {
		return await http.delete(`${apiPath}`);
	}*/
}

export default new apiCall();