import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Hero(props) {
   
    return (
        <section className="hero_bg">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="hero_wrap">
                            <div className="hero_text">
                                <h4 className="sub_head">{props.subTitle}</h4>
                                <h2 className="mb-4">{props.mainTitle}</h2>
                                <div dangerouslySetInnerHTML={{ __html: props.descText }} />
                                <Link to="/signin"><Button variant="default" className="gold_btn mt-4">Ask A Question</Button></Link>
                            </div>
                            <div className="hero_img">
                                {/*<img className="img-fluid" src={props.heroImg} alt={props.subTitle}/>*/}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
