import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ProtectedRoute from './helpers/ProtectedRoute';

import Home from './landingPage/pages/MainPage';
import SignIn from './components/users/SignIn';
import SignUp from './components/users/SignUp';
import SignUpOTP from './components/users/SignUpOTP';
import Forgotpassword from './components/users/forgotPassword';
import Resetpassword from './components/users/resetPassword';
//import AboutUs from './landingPage/pages/AboutUs';
import ContactUs from './landingPage/pages/ContactUs';
import ComPage from './landingPage/pages/ComPage';
import AskedQues from './components/questions/Ask';
import MyQues from './components/questions/My';
import ThankyouQues from './components/questions/Thankyou';
import GiveYourFeedback from './components/questions/GiveYourFeedback';
import Dashbaord from './components/Dashboard';
import Referral from './components/invitesFriends/Referral';
import quesPayment from './components/payments/quesPayment';
import eventPayment from './components/payments/eventPayment';
import ScheduleAppt from './components/questions/ScheduleAppt';
import Profile from './components/profile/Profile';
import AccountSetting from './components/profile/AccountSetting';

import findLocalPro from './components/contractors/findLocal';
import ContractorSignup from './components/contractors/signup';
import ContractorSignupThankyou from './components/contractors/signup/Thankyou';

//import Room from './components/enablexCall/Room';

import TalkShowInfo from './components/talkShow/info';
import Player from './components/talkShow/vPlayer';

const App = (props) => {
	//console.log('app con: ',props.prsAllCmsData); <ProtectedRoute path="/room/:token" children={<Room />} />
	return (
		<Switch>
			<Route exact path="/" component={() => <Home pageData={props.prsAllCmsData} />} />
			<Route path="/signin" component={SignIn} />
			<Route path="/via-referral/signup/:encryptedId" component={SignUp}  />
			<Route path="/signup" component={SignUp} />
			<Route path="/signupwithotp" component={SignUpOTP} />				
			<Route path="/forgot-password" component={Forgotpassword}   />
			<Route path="/recover/password/:id/:token" component={Resetpassword}  />
			<Route path="/about-us" component={() => <ComPage pageSlug="about-us" pageData={props.prsAllCmsData} />} />
			<Route path="/contact-us" component={() => <ContactUs pageSlug="contact-us" pageData={props.prsAllCmsData} />} />
 			<Route path="/privacy-policy" component={() => <ComPage pageSlug="privacy-policy" pageData={props.prsAllCmsData} />} />
			<Route path="/terms-conditions" component={() => <ComPage pageSlug="terms-conditions" pageData={props.prsAllCmsData} />} />
			
			<Route path="/talk-show" component={() => <TalkShowInfo pageSlug="talk-show" pageData={props.prsAllCmsData} />} />
			<ProtectedRoute path="/live-talk-show" component={Player} />
			
			<Route path="/find-local-professionals" component={findLocalPro} />	
			<Route path="/contractor/create/account" component={() => <ContractorSignup pageData={props.prsAllCmsData} />} />
			<Route path="/contractor/thankyou" component={ContractorSignupThankyou} />			
			
			<ProtectedRoute path="/payment/question/:encryptAskedQuesId" component={quesPayment} />
			<ProtectedRoute path="/payment/event/:encryptEventId" component={eventPayment} />
			<ProtectedRoute path="/schedule/question/appointment/:encryptAskedQuesId" component={ScheduleAppt} />
			<ProtectedRoute path="/dash" component={Dashbaord} />
			<ProtectedRoute path="/referral" component={Referral} />
			<ProtectedRoute path="/question/asked" component={AskedQues} />
			<ProtectedRoute path="/question/my" component={MyQues} />
			<ProtectedRoute path="/question/thankyou" component={ThankyouQues} />
			<ProtectedRoute path="/drop-your-feedback/:askedQuesId" component={GiveYourFeedback}  />	
			<ProtectedRoute path="/profile" component={Profile} />
			<ProtectedRoute path="/account-setting" component={AccountSetting} />		
			
		</Switch>
	);
};

export default withRouter(App);
