import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form,Spinner } from 'react-bootstrap';
import { useHistory,Link } from "react-router-dom";
import Button from '../shared/UI/Button';
import AlertDismissible from '../shared/aleartmsg';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import config from '../../helpers/config.json';
import Toastmsg from '../shared/toastmsg';
import Logo from '../../landingPage/images/TE_icon_logo.svg';
//import {useDispatch} from 'react-redux';
//import { loggedInUserDetails } from '../../actions/userAction';

const loginform = yup.object().shape({
    Email: yup.string().required("Oops! You didn't enter an email.").email('Please enter a valid email.'),
    userPassword:yup.string().required("Oops! You didn't enter an password.").min(6,"Enter 6-digit password")
});

const SignIn = () =>{
    const history = useHistory();
	//const dispatch = useDispatch();
	
	const [loader,setLoader] = useState(false);
	const [btnColor,setBtnColor] = useState(false);
	const [aleart,setAleart] = useState(null);
	const [aleartmsg,setAleartmsg] = useState(null);
    
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(loginform)
	});
	
	const loginSubmit = async (data) =>{
		
		setLoader(true);
		setBtnColor(true);
		
		let user = {email:data.Email, password:data.userPassword }

        try{
            let res = await apiCall.create(apiEndPoint.userCheckLogin,user);
            if(res && res.data.status === "success"){
				//dispatch(loggedInUserDetails(res.data.userData));
				localStorage.setItem('Token',res.data.accessToken);
				localStorage.setItem('refreshToken',res.data.refreshToken);
				localStorage.setItem('TEuserData',JSON.stringify(res.data.userData));
				history.push('/dash');
            }else if(res && res.data.status === "error"){
                let alaerts = { alearttype:"danger", msg:res.data.message }
                setAleart(true);
                setAleartmsg(alaerts);
            }
		}catch(err){
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:config.ERROR_MSGS.BAD_REQUEST });		
		}
		setLoader(false);
		setBtnColor(false);
	};
	
	return(
         <>
            <div className="reg_wrap">
 				<Form className="reg_form" onSubmit={handleSubmit(loginSubmit)}>
 					<Link to="/"><img src={Logo} alt="" className="mb-4" /></Link>
					<h2 className="mb-2">Log in to your account</h2>
					<span>New to {config.PROJECT_TITLE}? <Link to="/signup">Create an account</Link>.</span>
					<AlertDismissible showaleart={aleart} aleartmsg={aleartmsg} />
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label className="form_label">Email *</Form.Label>
						<Form.Control type="text" placeholder="" {...register("Email")}  className={`forms_input ${errors.Email ? 'invalid' : 'forms_input_border'}`} />
						<div className="Err-feedback">
							<span>{errors.Email?.message}</span>
						</div>
					</Form.Group>
					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Label className="form_label">Password *</Form.Label>
						<Form.Control type="password" placeholder="" {...register("userPassword")} className={`forms_input ${errors.userPassword ? 'invalid' : 'forms_input_border'}`} />
						<div className="Err-feedback">
							<span>{errors.userPassword?.message}</span>
						</div>
					</Form.Group>
					<Button type="submit" loadcolor={btnColor} disabledSts={loader} >
						{loader ? <Spinner animation="border" role="status" size="sm" className="m-2" />:'Login'}
					</Button>
					<p className="acc_mode_change">Lost your password? <Link to="/forgot-password">Click here</Link></p>
				</Form>
			</div>
         </>
     )
}

export default SignIn;