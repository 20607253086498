import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form,Spinner } from 'react-bootstrap';
import { Link,useHistory } from 'react-router-dom';
import AlertDismissible from '../../components/shared/aleartmsg';
import Button from '../../components/shared/UI/Button';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import config from '../../helpers/config.json';
import Toastmsg from '../../components/shared/toastmsg';

const registerform = yup.object().shape({
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    userNumber: yup.string().required('Contact number is required').matches(/^([0-9][0-9]*)$/,"Please enter numeric value").min(10,"Enter 10-digit contact number"),
    userPassword:yup.string().required('Password is required!').min(6,"Your password must be at least 6 characters!")
});

const Completesignup = (props) =>{
	
	const history = useHistory();
	
	const [loader,setLoader] = useState(false);
	const [aleart,setAleart] = useState(null);
	const [aleartmsg,setAleartmsg] = useState(null);
	const [btnColor,setBtnColor] = useState(false);
	
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(registerform)
	});
	
	const registerSubmit = async(data) =>{ 
	
		setLoader(true);
		setAleart(null);
		setAleartmsg(null);
		setBtnColor(true);
		
		let user = {
			email:props.email,
			referredBy:props.referredByUser,
			firstName:data.firstName,
			lastName:data.lastName,
			contactNo:data.userNumber,
			password:data.userPassword,
			ipAddress:''
		}

        try{
			let res = await apiCall.postPublic(apiEndPoint.rgisteruser,user)
 			if(res && res.data.status === "success"){
 				history.push('/signin');
			}else if(res && res.data.status === "error"){
				setAleart(true);
				setAleartmsg({ alearttype:"danger", msg:res.data.message });
			}
		}catch(err){
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:config.ERROR_MSGS.BAD_REQUEST });			
		}   
		setLoader(false);
		setBtnColor(false);
	};
    
	return(
         <>
			<div className="reg_wrap">
				
				   <Form className="reg_form" onSubmit={handleSubmit(registerSubmit)}>
					   <h2>Complete your profile</h2>
					   <span>Welcome to {config.PROJECT_TITLE}!</span>
					   <AlertDismissible showaleart={aleart} aleartmsg={aleartmsg} />
 					   <Form.Group className="mb-3" controlId="formBasicName">
					   <Form.Label className="form_label ctrl-lbl">First Name *</Form.Label>
						   <Form.Control type="text" placeholder="" {...register("firstName")}  className={`forms_input ${errors.firstName ? 'invalid' : 'forms_input_border'}`} />
						   <div className="Err-feedback" >
								<span> {errors.firstName?.message}</span>
						   </div>
					   </Form.Group>
	
					   <Form.Group className="mb-3" controlId="formBasilname">
						   <Form.Label className="form_label ctrl-lbl">Last Name *</Form.Label>
						   <Form.Control type="text" placeholder="" {...register("lastName")}  className={`forms_input ${errors.lastName ? 'invalid' : 'forms_input_border'}`} />
						   <div className="Err-feedback" >
						   <span>{errors.lastName?.message}</span>
						   </div>
					   </Form.Group>
	
					   <Form.Group className="mb-3" controlId="formBasicNumber">
						   <Form.Label className="form_label ctrl-lbl">Contact # *</Form.Label>
						   <Form.Control type="text" placeholder="" {...register("userNumber")}  className={`forms_input ${errors.userNumber ? 'invalid' : 'forms_input_border'}`} />
						   <div className="Err-feedback" >
						   <span>{errors.userNumber?.message}</span>
						   </div>
					   </Form.Group>
	
					   <Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label className="form_label ctrl-lbl">Password *</Form.Label>
						   <Form.Control type="password" placeholder="" {...register("userPassword")} className={`forms_input ${errors.userPassword ? 'invalid' : 'forms_input_border'}`} />
						   <div className="Err-feedback" >
						   <span>{errors.userPassword?.message}</span>
						   </div>
					   </Form.Group>
					   
					   <Button type="submit" loadcolor={btnColor} disabledSts={loader} >
							{loader ? <Spinner animation="border" role="status" size="sm" className="m-2" />:'Create Now!'}
						</Button>
					   <p className="acc_mode_change">By creating an account, you agree to our <Link to="/signup">Terms of Service</Link> and <Link to="/signup">Privacy Policy</Link>.</p>
				   </Form>
			</div>
         </>
     )
}

export default Completesignup;