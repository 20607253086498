import React,{useState} from 'react';
/*import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import { fetchAllCMSAction } from '../../actions/cmsAction';
import { useDispatch, connect } from 'react-redux';*/

import { Link } from 'react-router-dom';
import { Col, Container, Row, Tabs, Tab } from 'react-bootstrap';
import Hero from '../components/hero/Hero';
import PageTitle from '../components/elements/PageTitle';
import LpService from '../components/lpServices/LpService';
import HowWork from '../components/howitwork/HowWork';
import HeroImg from '.././images/eh_1.png';
import Ready from '../components/elements/Ready';
import OwnerBio from '../components/about_owner/OwnerBio';
import MultiSerOffer from '../components/mso/MultiSerOffer';
import Story from '../components/blogs/Story';
import BlogImg1 from '../images/bs_img1.jpg';
import BlogImg2 from '../images/bs_img2.jpg';
import BlogImg3 from '../images/bs_img3.jpg';
import ReviewCarousel from '../components/carousel/ReviewCarousel';

const MainPage = (props) => {
	//const dispatch = useDispatch();
	const [homeData] = useState(props.pageData);
	const reviewsData = homeData.filter(homeData => (homeData.pageName === 'testimonial' && homeData.sectionType === 'list'));
	//const [testimonialData,setTestimonialData] = useState([]);
	//const [homeDataLoading,setHomeDataLoading] = useState(null);
	
	/*async function fetchHomeData(){
		//setHomeDataLoading(true)
		try{
			if(homeData.length===0){
				var response = await apiCall.getPublic(apiEndPoint.allCMSData);
				setHomeData(response.data);
				dispatch(fetchAllCMSAction(response.data));
				console.log('yes home')
			}
			//setHomeDataLoading(false)
		}catch(err){
			//setHomeDataLoading(false)
		}
	}
	
	useEffect(()=>{		
		fetchHomeData();
	},[]) */
	
    return (
        <>
		{homeData.filter(homeData => homeData.sectionType === 'top-header').map(s => (
			<Hero 
				subTitle={s.secTitle}
				mainTitle={s.secSubTitle}
				descText={s.secShortDesc}
				heroImg={HeroImg}
			/>
		))}
        <Container id="services">
            <Row className="pt-5">
                <Col md={12}>
					{homeData.filter(homeData => (homeData.pageName === 'home' && homeData.sectionType === 'services')).map(st => (
						<PageTitle
							subTitle={st.secTitle}
							titleHeading={st.secSubTitle}
							titleInfo={st.secShortDesc}
						/>
					))}
                </Col>
                <Col md={12}>
                    <div  className="lpservice_wrap mt-5">
						<Tabs defaultActiveKey="0"  className="service_tabs" id="" transition={false}>
							{homeData.filter(homeData => (homeData.pageName === 'service' && homeData.sectionType === 'list')).map((serData, index) => (
							<Tab eventKey={index} title={serData.titleOne}>
								<LpService serDetails={serData} />
							</Tab>
							))}
						</Tabs>
					</div>
                </Col>
            </Row>
		</Container>
		<Container id="howItWorks" className="pt-5">
			{homeData.filter(homeData => homeData.sectionType === 'how-it-works').map(hw => (
			<Row   >			
				<Col md={12}>
					<PageTitle
						subTitle={hw.secTitle}
						titleHeading={hw.secSubTitle}
						titleInfo={hw.secShortDesc}
					/>
				</Col>
				<Col md={12}>
					<HowWork hwData={hw} />					                    
				</Col>			
			</Row>
			))}
        </Container>
        <section className="bgDark pt-5 mt-4 rtgstrd">
            <Container>
                <Row>
                    <Col>
						{homeData.filter(homeData => (homeData.pageName === 'home' && homeData.sectionType === 'ready-to-start')).map(rtgs => (
							<Ready 
								titleHeading={rtgs.secTitle}
								titleInfo={rtgs.secSubTitle}
								btn_text={rtgs.secShortDesc}
								linkTo="/signup"
							/>
						))}
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="dark_light_gradient rtgstrd" id="aboutUs">
            <Container className="pt-5 pb-5">
                <Row>
                    <Col>
                       {homeData.filter(homeData => homeData.sectionType === 'about-us').map(aboutUs => (
							<OwnerBio aboutUsDetails={aboutUs} />
						))}
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="bgLight pt-5 pb-4" id="reviews">
            <Container>
                <Row>
                <Col md={12}>
					{homeData.filter(homeData => (homeData.pageName === 'home' && homeData.sectionType === 'testimonial')).map(tstm => (
						<PageTitle
							subTitle={tstm.secTitle}
							titleHeading={tstm.secSubTitle}
							titleInfo={tstm.secShortDesc}
						/>
					))}
                </Col>
                </Row>
            </Container>
			<ReviewCarousel reviewsData={reviewsData} />
        </section>
        <section className="bg-white pt-5 pb-5">
            <Container>
                <Row>
                <Col md={12}>
					{homeData.filter(homeData => homeData.sectionType === 'multi-services-offered').map(mso => (
                    	<MultiSerOffer msoDetails={mso} />
					))}
                </Col>
                </Row>
            </Container>
        </section>
       {/* <section className="white_light_gradient pt-5 pb-5" id="blog">
            <Container>
                <Row className="home_blog_list">
                    <Col md={12}>
                        {homeData.filter(homeData => (homeData.pageName === 'home' && homeData.sectionType === 'blog')).map(blog => (
							<PageTitle
								subTitle={blog.secTitle}
								titleHeading={blog.secSubTitle}
								titleInfo={blog.secShortDesc}
							/>
						))}
                    </Col>
                    <Col md={4}>
                        <Story
                            storyImg={BlogImg1}
                            Date="24"
                            Month="June"
                            storyTitle="Marketing"
                            storyInfo="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
                        />
                    </Col>
                    <Col md={4}>
                        <Story
                            storyImg={BlogImg2}
                            Date="21"
                            Month="June"
                            storyTitle="Marketing"
                            storyInfo="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
                        />
                    </Col>
                    <Col md={4}>
                        <Story
                            storyImg={BlogImg3}
                            Date="20"
                            Month="June"
                            storyTitle="Marketing"
                            storyInfo="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
                        />
                    </Col>
                    <Col md={12} className="text-center mt-4">
                        <Link to="/blogs" className="btn gold_btn mx-auto">Read All Blogs</Link>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="bgDark pt-5">
            <Container>
                <Row>
                    <Col>
                        {homeData.filter(homeData => (homeData.pageName === 'home' && homeData.sectionType === 'ready-to-start')).map(rtgs => (
							<Ready 
								titleHeading={rtgs.secTitle}
								titleInfo={rtgs.secSubTitle}
								btn_text={rtgs.secShortDesc}
								linkTo="/signup"
							/>
						))}
                    </Col>
                </Row>
            </Container>
        </section>*/}
        </>
        
    )
}

export default MainPage;

/*const mapStateToProps = (state) => {
	return { oncAllCMSData: state.stCMSData };
}

export default connect(mapStateToProps)(MainPage);*/