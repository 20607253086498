import React from "react";
import DropIn from "braintree-web-drop-in-react";
import { Col, Container, Row,Tabs ,Tab,Table } from 'react-bootstrap';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
 
class Setup extends React.Component {
  instance;
 
  state = {
    clientToken: null,
  };
 
  async componentDidMount() {
    // Get a client token for authorization from your server
    /*const response = await apiCall.getAll('payments/client_token'); // Calling Get API for getting all my asked questions		
    const clientToken = response.data.clientToken
 
    this.setState({
      clientToken,
    });*/
  }
  
  async paynow(){
	const response = await apiCall.get('payments/client_token'); // Calling Get API for getting all my asked questions		
	const clientToken = response.data.clientToken
	
	this.setState({ 
		clientToken,
	});
  }
 
  async buy() {
    // Send the nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();
	const reqData = {
		payment_method_nonce:nonce,
		payAmt:10.00
	}
	//console.log('nonce: ',nonce);
	try{
		const res = await apiCall.create('payments/received',reqData);
			
		if(res.data.success){
			//console.log('res: ',res);	
		}else{
			console.log('res error msg: ',res.data.message);	
		}
	}catch(error){
		//setErrMsg(error);
	}
  }
 
  render() {
    if (!this.state.clientToken) {
      return (
        <>
          <button onClick={this.paynow.bind(this)}>Pay Now!</button>
        </>
      );
    } else {
      return (
        <section className="inner_wrapper py-4">
			<Container>
				<Row>
					<Col md={12}>
					  <DropIn
						options={{ authorization: this.state.clientToken }}
						onInstance={(instance) => (this.instance = instance)}
					  />
					  <button onClick={this.buy.bind(this)}>Pay Now!</button>
				   </Col>
				</Row>
			</Container>
		</section> 
      );
    }
  }
}

export default Setup