import React from "react";
import Helmet from "react-helmet"
import InnerTitle from '../title/InnerTitle';
import config from '../../helpers/config.json';
import DropIn from "braintree-web-drop-in-react";
import { Link } from 'react-router-dom';
import { BsFillChatQuoteFill } from "react-icons/bs";
import { Col, Container, Row, Badge, Tabs ,Tab,Table, Spinner } from 'react-bootstrap';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens';
import ScheduleAppt from '../questions/ScheduleAppt';
import {connect} from 'react-redux';
import { fetchUpcomingEventsAction } from '../../actions/eventAction';
 
class eventPayment extends React.Component {
	instance;	
	
	state = {
		clientToken: null,
		selectedEventDetails: [],
		selectedEncryptEventId: '',
		loaderProceed: false,
		pageTitle: 'Event Fee',
		pageSubTitle: 'Checkout',
		months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug","Sep", "Oct", "Nov", "Dec"]
	};  
	
	async componentDidMount(){
		
		const encryptEventId = this.props.match.params.encryptEventId;
		//const curUpcomingEvents = this.props.curUpcomingEvents;			
		//const checkoutEventData = curUpcomingEvents.filter(e => e.encryptEventId === encryptEventId);
		const resEvent = await apiCall.get(apiEndPoint.singleEventDetailsInArr + encryptEventId);
		const checkoutEventData = resEvent.data;		
		
		const response = await apiCall.get('payments/client_token'); // Get Token from Braintree	
		const clientToken = response.data.clientToken;
		
		this.setState({ 
			clientToken,
			selectedEventDetails: checkoutEventData,
			selectedEncryptEventId: encryptEventId
		});
		
		/*const resEvent = await apiCall.get(apiEndPoint.upcomingEvents+'1');
		console.log('test fsa',resEvent.data);
		//this.props.store.dispatch(fetchUpcomingEventsAction(resEvent.data));
		const { dispatch } = this.props;                
    	dispatch(fetchUpcomingEventsAction(resEvent));*/
		 
	}
 
	async buy() {
		// Send the nonce to your server
		
		const logUser = serviceToken.getLoggedInUser();
		const userId = logUser.userId;
		const eventId = this.state.selectedEventDetails[0].eventId;
	
		const { nonce } = await this.instance.requestPaymentMethod();
		const reqData = {
			payment_method_nonce:nonce,
			payAmt:this.state.selectedEventDetails[0].joinFee
		}
		//console.log('nonce: ',nonce);
		try{
			this.setState({ 
				loaderProceed: true
			});
			const res = await apiCall.create('payments/received',reqData);
			if(res.data.success){
				//console.log('res: ',res.data.transaction);			
				const paymentBy = res.data.transaction.paymentInstrumentType;
				if(paymentBy=='paypal_account'){
					var payerId = res.data.transaction.paypal.payerId;
					var payerStatus = res.data.transaction.paypal.payerStatus;
					var paymentId = res.data.transaction.paypal.paymentId;
					var transactionFeeAmount = res.data.transaction.paypal.transactionFeeAmount;
					var cardLast4 = '';
					var cardType = '';
				}else{
					var cardLast4 = res.data.transaction.paymentReceipt.cardLast4;
					var cardType = res.data.transaction.paymentReceipt.cardType;
					var payerId = '';
					var payerStatus = '';
					var paymentId = '';
					var transactionFeeAmount = '';
				}
				
				const paymentData = {
					actionForId: eventId,
					userId: userId,
					actionFor: 1,
					paymentBy: paymentBy,
					merchantAccountId: res.data.transaction.merchantAccountId,
					status: res.data.transaction.status,				
					type: res.data.transaction.type,
					settlementBatchId: res.data.transaction.settlementBatchId,
					txnId: res.data.transaction.paymentReceipt.id,
					txnAmount: res.data.transaction.paymentReceipt.amount,
					currencyIsoCode: res.data.transaction.paymentReceipt.currencyIsoCode,
					globalId: res.data.transaction.paymentReceipt.globalId,
					cardLast4: cardLast4,
					cardType: cardType,
					paypalPayerId: payerId,
					paypalPayerStatus: payerStatus,
					paypalPaymentId: paymentId,
					paypalTransFeeAmount: transactionFeeAmount,
					merchantIdentificationNo: res.data.transaction.paymentReceipt.merchantIdentificationNumber,
					merchantName: res.data.transaction.paymentReceipt.merchantName,
					pinVerified: res.data.transaction.paymentReceipt.pinVerified,
					processingMode: res.data.transaction.paymentReceipt.processingMode,
					processorAuthCode: res.data.transaction.paymentReceipt.processorAuthorizationCode,
					processorResCode: res.data.transaction.paymentReceipt.processorResponseCode,
					processorResText: res.data.transaction.paymentReceipt.processorResponseText,
					terminalIdentificationNo: res.data.transaction.paymentReceipt.terminalIdentificationNumber				
				}
				const payRes = await apiCall.create('payments/success_entry',paymentData);
				
				if(payRes && payRes.data.status === "success"){
					
					const resEvent = await apiCall.get(apiEndPoint.upcomingEvents+userId);
					//this.props.store.dispatch(fetchUpcomingEventsAction(resEvent.data));
					const { dispatch } = this.props;                
    				dispatch(fetchUpcomingEventsAction(resEvent.data));
					this.props.history.push('/dash');
				}else{
					//setErrMsg(res.data.message);
				}
	
			}else{
				console.log('res error msg: ',res.data.message);	
			}
		}catch(error){
			//setErrMsg(error);
		}
	}
 
  render() {    
      return (
        <section className="inner_wrapper pb-5 paymentPage">	
			<Helmet>
				<title>Checkout - {config.PROJECT_TITLE}</title>
			</Helmet>
			<Container>
				<Row>
					<Col md={12}>						
						<InnerTitle
							subTitle={this.state.pageTitle}
							titleHeading={this.state.pageSubTitle}
						/>
					</Col>
				</Row>
				{!this.state.clientToken ?
					<Row>
						<Col md={12}>
							<div className="ques_wrap"><h5>Please Wait <Spinner animation="border" role="status" size="sm"  /></h5></div>
						</Col>
					</Row>
				:
					<Row>
						<Col md={12}>
						<div className="ques_wrap">
						{this.state.selectedEventDetails.map(e => (
							<div className="my_ques chk_event">									
								<div className="mq_head">
									<h6>{e.eventTitle}</h6>
									<div className="hqh_rgt">									
										<Badge pill bg="success" className="ques_price">${e.joinFee}</Badge>
									</div>
								</div>
								<div className="mq_body">
									<ul className="mq_status">
										<li><small>Event Date</small><span>{new Date(parseInt(e.eventDate)).getDate()} {this.state.months[(new Date(parseInt(e.eventDate)).getMonth())]} {new Date(parseInt(e.eventDate)).getFullYear()}, {new Date(parseInt(e.eventDate)).getHours()}:{new Date(parseInt(e.eventDate)).getMinutes()}</span></li>
										<li><small>Topic</small><span>{e.eventTopic.topicName}</span></li>
										<li><small>Host</small><span>{e.hostBy}</span></li>
										<li><small>Duration</small><span>{e.eventDuration+' min.'}</span></li>										
									</ul>								
								</div>
								<div className="btns_grp">
									<div style={{ width: '100%' }}>			 
										<DropIn
											options={{
												authorization: this.state.clientToken,
												paypal: {
													flow: "vault",
													buttonStyle: {
													  color: 'blue',
													  shape: 'rect',
													  size: 'medium'
													}
												},
											}}
											onInstance={(instance) => (this.instance = instance)}
										/>
									</div>
								</div>
								<div className="btns_grp">
									<button onClick={this.buy.bind(this)} className="btn gold_btn">{!this.state.loaderProceed ? 'Make Payment' : <>Processing <Spinner animation="border" role="status" size="sm"  /></>}</button>&nbsp;&nbsp;
									<Link className="btn gold_btn" to={'/dash'}>Back</Link>
								</div> 								
							
							</div>
						))}
						</div>
					</Col>
				</Row>
				}
           </Container>
		</section>         
      );     
  }
}

const mapStateToProps = (state) => {
	return { curUpcomingEvents: state.stUpcomingEvents };
}

export default connect(mapStateToProps)(eventPayment);