import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import reducers from './reducers';
//import App from './App';
import Layout from './landingPage/Layout';
import './assets/css/main.css';
import './assets/css/media.css';

//console.log('Index File');
const Routing = () => {
  return(
	<Provider store={createStore(reducers,composeWithDevTools(applyMiddleware(logger, thunk)))}>
		<Router>
			<Layout />
		</Router>
	</Provider>
  )
}

ReactDOM.render(
	<React.StrictMode>
		<Routing />
	</React.StrictMode>,
	document.querySelector('#root')
);
