import React from 'react';
import { Col, Container, Row, Badge, BadgeProps } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ThankyouQues = () => {
  return (		
    <section className="inner_wrapper">
		<Container>
			<Row>
				<Col>
					<div className="page_title">
						<h2>Thank You :)</h2>
  						<h5 className="mt-5">Consultant will contact you shortly!</h5>
						{/*<Link className="btn gold_btn m-4" to='/question/my'>Back to Questions List</Link>*/}
					</div>
				</Col>
			</Row>
		</Container>
	</section>  
  );
};

export default ThankyouQues
