import React from 'react'

export default function Review(props) {
    return (
        <div className="reviewBox">
            <div className="quote_icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="31.5" viewBox="0 0 36 31.5">
                <path id="Icon_awesome-quote-left" data-name="Icon awesome-quote-left" d="M32.625,18H27V13.5A4.5,4.5,0,0,1,31.5,9h.563A1.683,1.683,0,0,0,33.75,7.313V3.938A1.683,1.683,0,0,0,32.063,2.25H31.5A11.247,11.247,0,0,0,20.25,13.5V30.375a3.376,3.376,0,0,0,3.375,3.375h9A3.376,3.376,0,0,0,36,30.375v-9A3.376,3.376,0,0,0,32.625,18Zm-20.25,0H6.75V13.5A4.5,4.5,0,0,1,11.25,9h.563A1.683,1.683,0,0,0,13.5,7.313V3.938A1.683,1.683,0,0,0,11.813,2.25H11.25A11.247,11.247,0,0,0,0,13.5V30.375A3.376,3.376,0,0,0,3.375,33.75h9a3.376,3.376,0,0,0,3.375-3.375v-9A3.376,3.376,0,0,0,12.375,18Z" transform="translate(0 -2.25)" fill="#e2b327"/>
                </svg>
            </div>
            <div className="review_words">
                <div dangerouslySetInnerHTML={{ __html: props.review }} />
            </div>
            <div className="rev_by">
                {/*<div className="rev_img"><img src={props.revImg} alt={props.revName}/></div>*/}
                <h5>{props.revName} <span>{props.revDesi}</span></h5>
            </div>
        </div>
    )
}
