import React,{useState} from 'react';
import Helmet from "react-helmet";
import { Col, Container, Row, Form, Spinner, Card } from 'react-bootstrap';
import Button from '../shared/UI/Button';
//import { FaRegHandPointRight } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens'; 
import config from '../../helpers/config.json';
import AlertDismissible from '../shared/aleartmsg';
import RefImg from '../../assets/images/Referral_program.svg';
import InnerTitle from '../title/InnerTitle';
 
const loginform = yup.object().shape({
    friendsEmailAddress: yup.string().required("Oops! You didn't enter an email.")
});

const Referral = () => {
	
	const logUser = serviceToken.getLoggedInUser();
	const referralAmt = 10;
	const userId = logUser.userId;
	const [loader,setLoader] = useState(false);
	const [btnColor,setBtnColor] = useState(false);
	const [aleart,setAleart] = useState(null);
	const [aleartmsg,setAleartmsg] = useState(null);
	
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(loginform)
	});
	
	const frmSubmit = async (data,e) =>{		
		setLoader(true);
		setBtnColor(true);		
		let frmData = {emailAddresses:data.friendsEmailAddress, userId:userId }
        try{
            let res = await apiCall.create(apiEndPoint.referralInviteFriends,frmData);
            if(res && res.data.status === "success"){
				e.target.reset()
				setAleart(true);
				setAleartmsg({ alearttype:"success", msg:res.data.message });
            }else if(res && res.data.status === "error"){
                setAleart(true);
                setAleartmsg({ alearttype:"danger", msg:res.data.message });
            }
		}catch(err){
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:config.ERROR_MSGS.BAD_REQUEST });		
		}
		setLoader(false);
		setBtnColor(false);
	}
	  
	return (
		<section className="inner_wrapper pb-5 ref-pgrm">
			<Helmet>
				<title>Refer Your Friends - {config.PROJECT_TITLE}</title>
			</Helmet>
			<Container className="ref-pgrm-content">
			<Col md={12}>						
				<InnerTitle
					subTitle="Join Our"
					titleHeading="Referral Program"
				/>
				<div className="ref_img mb-3 mt-5"><img className="img-fluid" src={RefImg} alt=""/></div>
			</Col>
				<Row >
					<Col md={12}>
						<p>Refer your friends and family to open a new account on {config.PROJECT_TITLE}. You will receive a <b>${referralAmt} Bonus Reward</b> amount when your friend meets qualifying account activities.</p>
						<p>Hi {logUser.firstName}, help your friends to take solutions of its Questions / Problems from Top Consultant. </p>
						<p>Invite your friends to <b>{config.PROJECT_TITLE}</b> and earn!</p>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						
						<div className="pt-4 pb-3 ps-3 pe-3 mb-4 shadow rounded bg-white">
						<Form onSubmit={handleSubmit(frmSubmit)}> 
							<Form.Group controlId="formBasicPassword">
								<Form.Label className="form_label">Enter email addresses, separated by comma:</Form.Label>
								<Form.Control as="textarea" rows={6} placeholder="example1@gmail.com, example2@gmail.com, example3@gmail.com, example4@gmail.com, example5@gmail.com" {...register("friendsEmailAddress")} className={` ${errors.friendsEmailAddress ? 'invalid' : 'forms_input_border'}`} />
								<div className="Err-feedback">
									<span>{errors.friendsEmailAddress?.message}</span>
								</div>
							</Form.Group>
							<Button type="submit" loadcolor={btnColor} disabledSts={loader} >
								{loader ? <Spinner animation="border" role="status" size="sm" className="m-2" />:'Send Invites'}
							</Button>
						</Form>
						<AlertDismissible showaleart={aleart} aleartmsg={aleartmsg} />
						</div>
					</Col>
				</Row>
				<div className="reff_inst">
				   <h6>Once you've sent the invite, your friend will need to complete below mentioned steps before you receive the ${referralAmt} reward:</h6>
				   <ul>
					   <li>Click on the invite link in the referral email and sign up.</li>
					   <li>You can earn ${referralAmt} for every friend who sign up into application.</li>
				   </ul>
				</div>
			
			</Container>
		</section> 
	);
};
export default Referral