// import {FORGOT_PASSWORD_ERR} from '../actions/type/passwordType';

// export const getMsg = (data) => {
//     console.log('my action here',data)
//         return {
//             type: 'FORGOT_PASSWORD_ERR',
//             payload:"hello"
//           }
 
// }


export const getMsg = RData => {
  console.log('my action here',RData)  
    return {
      type: 'REMINDER_ADD',
      payload: RData
    };
  };
  
