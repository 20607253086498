import React from 'react';
import Helmet from "react-helmet";
import { Col, Container, Row } from 'react-bootstrap';
import config from '../../../helpers/config.json';
import InnerTitle from '../../title/InnerTitle';

export default function Thankyou() {
	
	return (
		<section className="inner_wrapper mb-5">
			<Helmet>
				<title>{'Thank You - '+config.PROJECT_TITLE}</title>
			</Helmet>
			<Container>
				<Row>
					<Col>
						<InnerTitle
							subTitle=""
							titleHeading="Thank You"
						/>
					</Col>
				</Row>				
				<Row>
					<Col>
					</Col>
				</Row>				
			</Container>
		</section>
	);
}