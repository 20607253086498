import React from 'react';
import { Route, Redirect } from "react-router-dom";
import serviceToken from '../apis/serviceTokens'; 

const ProtectedRoute = ({ component: Component, ...rest }) => {
const userToken = serviceToken.getSessionAccessToken();
return(   
    <Route {...rest} render={(props) => (
		userToken 
			? <Component {...props} />
			: <Redirect to='/signin' />
		)} 
    />
)}

export default ProtectedRoute;