import React, { useRef } from "react";
import VideoJS from './VideoJS' // point to where the functional component is stored
import Helmet from "react-helmet";
import config from '../../helpers/config.json';
import InnerTitle from '../../components/title/InnerTitle';
import { Col, Container, Row } from 'react-bootstrap';

const Player = (props) => {
  const playerRef = useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
	poster: "https://www.talkelectrical.com/static/media/TE_icon_logo.72b1bd5c.svg",
    sources: [{
      src: 'https://talkelectrical.com/mnt/hls/stream1.m3u8', //rtmp:/162.240.26.132:1935/show/stream1
      type: 'application/x-mpegURL'
    }]
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  // const changePlayerOptions = () => {
  //   // you can update the player through the Video.js player instance
  //   if (!playerRef.current) {
  //     return;
  //   }
  //   // [update player through instance's api]
  //   playerRef.current.src([{src: 'http://ex.com/video.mp4', type: 'video/mp4'}]);
  //   playerRef.current.autoplay(false);
  // };

  return (
    <> 
		<section className="inner_wrapper pb-5 comPage">
			<Helmet>
				<title>{'Talk Show - '+config.PROJECT_TITLE}</title>
			</Helmet>
			<Container className="comPageCon">
				<Row>
					<Col md={12}>						
						<InnerTitle
							subTitle=""
							titleHeading="Talk Electrical Show"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={8}>						
						<VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
					</Col>
					<Col md={8}>
						<iframe src="https://chat.restream.io/embed?token=5e3d891a-0d20-4417-8cfe-2853b88fa0f6" title=""></iframe>
					</Col>
				</Row>
			</Container>			
		</section> 
    </>
  );
}
export default Player;
