import React from 'react';
import HwCard from './HwCard';
import hiwImg1 from '../../images/htw1.svg';
import hiwImg2 from '../../images/htw2.svg';
import hiwImg3 from '../../images/htw3.svg';
import hiwImg4 from '../../images/htw4.svg';
import hiwImg5 from '../../images/htw5.svg';

export default function HowWork(props) {
    return (
        <div className="hiw_list">
            <HwCard
             hiwImg={hiwImg1}
             hiwTtile={'1. '+props.hwData.titleOne}
             hiwDesc={props.hwData.titleOneSlug}
            />
            <HwCard
             hiwImg={hiwImg2}
             hiwTtile={'2. '+props.hwData.titleTwo}
             hiwDesc={props.hwData.titleTwoSlug}
            />
            <HwCard
             hiwImg={hiwImg3}
             hiwTtile={'3. '+props.hwData.subTitleOne}
             hiwDesc={props.hwData.subTitleTwo}
            />
            <HwCard
             hiwImg={hiwImg4}
             hiwTtile={'4. '+props.hwData.shortDescOne}
             hiwDesc={props.hwData.shortDescTwo}
            />
            <HwCard
             hiwImg={hiwImg5}
             hiwTtile={'5. '+props.hwData.contentDescOne}
             hiwDesc={props.hwData.contentDescTwo}
            />
        </div>
    )
}
