import React,{useEffect,useState} from 'react';
import { useParams } from "react-router";
import Helmet from "react-helmet"
import config from '../../helpers/config.json';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens';
import InnerTitle from '../title/InnerTitle';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row, Badge, BadgeProps, Card } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form, Spinner, Button } from 'react-bootstrap';
import { FaQuoteLeft } from "react-icons/fa";

const loginform = yup.object().shape({
    yourFeedbackTxt: yup.string().required("Please enter your feedback.")
});

const GiveYourFeedback = () => {
	const logUser = serviceToken.getLoggedInUser();
	const userId = logUser.userId;
	const history = useHistory();
	const { askedQuesId } = useParams();
	const mainHeading = 'Drop Your Feedback';
	const [errMsg,setErrMsg] = useState(null);
	const [loader,setLoader] = useState(false);
	const [quesLoaded, setQuesLoaded] = useState(false);
	const [myAskedQuesDetails,setMyAskedQuesDetails] = useState([]);
	
	async function fetchMyQuesDetails(){
		try{
			setQuesLoaded(false);		
			const response = await apiCall.get(apiEndPoint.fetchAskedQuesDetails+askedQuesId); // Calling Get API for getting all my asked questions		
			setMyAskedQuesDetails(response.data);
			setQuesLoaded(true);
		}catch(error){
			setErrMsg(error);
		}
	}
	
	useEffect(()=>{		
		fetchMyQuesDetails();
	},[]);
	
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(loginform)
	});
	
	const feedbackSubmit = async (data) =>{
		setLoader(true)
		let fbckReqData = {
			userId:userId,
			askedQuesId:askedQuesId,
			feedbackTxt:data.yourFeedbackTxt
		}
		
		try{
			const res = await apiCall.create(apiEndPoint.subCustYourFeedback,fbckReqData);
			console.log('res chk:',res.data);
			if(res && res.data.status === "success"){
				history.push('/question/thankyou');
			}else{
				setErrMsg(res.data.message);
			}
		}catch(error){
			setErrMsg(error);
		}
		setLoader(false)
	};
	
  return (		
    <section className="inner_wrapper">
		<Helmet>
			<title>{mainHeading} - {config.PROJECT_TITLE}</title>
		</Helmet>
		<Container>
			<Row>
				<Col>
					<InnerTitle
						subTitle=""
						titleHeading={mainHeading}
					/>
				</Col>
			</Row>
			{quesLoaded ? (
				<Row>
					<Col md={12}>
					<div className="ques_wrap">
						{myAskedQuesDetails.map(Ques => (
						<div className="te_card feedback_box">
							<Form className="" onSubmit={handleSubmit(feedbackSubmit)}>
							<div className="mq_head">
								<h6>Q. {Ques.quesId==0 ? Ques.otherQuesTitle : Ques.Question.quesTitle}</h6>
								<div className="hqh_rgt">									
									<Badge pill bg="success" className="ques_price">${Ques.quesAmt}</Badge>
								</div>
							</div>
							<div className="mq_body">
								
							<Form.Group className="mb-0">
											<Form.Label className="form_label"><FaQuoteLeft/></Form.Label>
											<Form.Control as="textarea" rows={5} placeholder="Write your review..." {...register("yourFeedbackTxt")}  className={`${errors.yourFeedbackTxt ? 'invalid' : 'forms_input_border'}`} />
											<div className="Err-feedback">
												<span>{errors.yourFeedbackTxt?.message}</span>
											</div>
										</Form.Group>
								 
								 
							</div>
							<div className="btns_grp">
								<Link className="btn gold_btn" to='/question/my'>Back</Link>
								<Button type="submit" className="btn gold_btn" disabled={loader}>
									{loader ? <Spinner animation="border" role="status" className="m-2" />:'Submit Now!'}
								</Button>
							</div>
							</Form>
						</div>
						))}						
					</div>
					</Col>
				</Row>
				) : (
					<Row>
						<Col md={12}>
							<div className="ques_wrap">
								<h4>Please wait, fetching data....</h4>
							</div>
						</Col>
					</Row>
				)}
		</Container>
	</section>  
  );
};

export default GiveYourFeedback
