import React,{useState} from 'react';
import Helmet from "react-helmet";
import config from '../../helpers/config.json';
import InnerTitle from '../../components/title/InnerTitle';
import { Col, Container, Row } from 'react-bootstrap';

const ComPage = (props) => {
	
	window.scrollTo(0, 0);
	const pageSlug = props.pageSlug;
 	const [comPageContent] = useState(props.pageData);
	
	return (
		<section className="inner_wrapper pb-5 comPage">
		{comPageContent.filter(comPageContent => comPageContent.sectionType === 'contact-us').map(c => (
			<>
				<Helmet>
					<title>{'Contact Us - '+config.PROJECT_TITLE}</title>
				</Helmet>
				<Container className="comPageCon">
					<Row>
						<Col md={12}>						
							<InnerTitle
								subTitle=""
								titleHeading="Contact Us"
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12}>						
							
						</Col>
					</Row>
				</Container>
			</>
		))}
		</section> 
	);
};

export default ComPage