import React,{useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form, Spinner } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useParams } from "react-router";
import Button from '../shared/UI/Button';
import SignUpOTP from './SignUpOTP';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import config from '../../helpers/config.json';
import AlertDismissible from '../shared/aleartmsg';
import Toastmsg from '../shared/toastmsg';
import Logo from '../../landingPage/images/TE_icon_logo.svg';

const schemaemail = yup.object().shape({
	Email: yup.string().required("Whoops. You didn't enter an email.").email('Please enter a valid email.'),
});
  
const SignUp = () =>{
	
	const { encryptedId } = useParams();	
	
	const [referredBy,setReferredBy] = useState(0);
	const [emailField,setEmailField] = useState('true');
	const [otpSubmit,setOtpSubmit] = useState(true);
	const [loader,setLoader] = useState(false);
	const [aleart,setAleart] = useState(null);
	const [aleartmsg,setAleartmsg] = useState(null);
	const [btnColor,setBtnColor] = useState(false);
	
	async function checkUserSts(){
		if(encryptedId !== undefined){
			try{
				const response = await apiCall.getPublic('user/valid/encrypted/'+encryptedId);
				setReferredBy(response.data.userId);
			}catch(err){
				setReferredBy(0);
			}
		}
	}
	
	useEffect(()=>{		
		checkUserSts();
	},[]);
	
	const { register, handleSubmit,reset, formState:{ errors } } = useForm({
		resolver: yupResolver(schemaemail)
	});
	
	const emailSubmit = async (data,e) =>{
		
		setLoader(true);
		  
 		setAleart(null);
		setAleartmsg(null);
		setBtnColor(true);
		
 		let user = {email:data.Email, referredBy: referredBy}
		try{
			let res = await apiCall.postPublic(apiEndPoint.registercheck,user);			
			if(res && res.data.status === "success"){
				setEmailField(data.Email);
				setOtpSubmit(false);
				e.target.reset()
			}else if(res && res.data.status === "error"){
				 
				setAleart(true);
				setAleartmsg({ alearttype:"danger", msg:res.data.message });
			
			}
		}catch(err){			
			 
			  setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:config.ERROR_MSGS.BAD_REQUEST });	     
		}	
		setBtnColor(false);  
		setLoader(false);
	};
	
	// get responce from child for true/false
	const resendotp = (val) =>{
		setOtpSubmit(val)
	}
	
	const descLine = "Enter your email and we'll send you a verification code.";
	
	return(
	<>
		{otpSubmit ? 
		(
        <div className="reg_wrap">
			
             
				<Form className="reg_form" onSubmit={handleSubmit(emailSubmit)}>
					<Link to="/"><img src={Logo} alt="" className="mb-4" /></Link>
					<h2>Create your account </h2>
					<span>{descLine}</span>
					<AlertDismissible showaleart={aleart} aleartmsg={aleartmsg} />
					<Form.Group className="mb-3" controlId="formBasicEmail">

						<Form.Label className="form_label">Email *</Form.Label>
						<Form.Control type="text" placeholder="" {...register("Email")} className={`forms_input ${errors.Email ? 'invalid' : 'forms_input_border'}`} />

						<div className="Err-feedback" >
						   <span>{errors.Email?.message}</span>
						</div>
					</Form.Group>
					
					<Button type="submit" loadcolor={btnColor} disabledSts={loader} >
						{loader ? <Spinner animation="border" role="status" size="sm" className="m-2" />:'Get Verification Code'}
					</Button>
					<p className="acc_mode_change">Already have an account? <Link to="/signin">Log-in</Link>.</p>
				</Form>
             </div>
         )
		:
			<SignUpOTP email={emailField} referredByUser={referredBy} changeotp={resendotp} />
		}
		</>
     )
}

export default SignUp;