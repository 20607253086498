import React,{useEffect, useState} from 'react';
import { useParams, useHistory } from "react-router";
import Helmet from "react-helmet"
import { Link } from 'react-router-dom';
import { Col, Container, Row, Badge, Tabs ,Tab, Table, Form, Spinner, Button } from 'react-bootstrap';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import {connect} from 'react-redux';
import InnerTitle from '../title/InnerTitle';
import config from '../../helpers/config.json';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';

const ScheduleAppt = (props) => {
 	 
	toast.configure();
	let { encryptAskedQuesId } = useParams();
	const history = useHistory();
	
	const pageSubTitle = 'Book Your Consulting Slot';
	const [ftrQuesData,setFtrQuesData] = useState([]);
	const [scheApptLoader,setScheApptLoader] = useState(false);
	const [errorMsg,setErrorMsg] = useState(null);
 	const [dt, setDt] = useState(moment());
	
 	async function fetchQuesDataFromState(){
		try{
			const response = await apiCall.get(apiEndPoint.fetchAskedQuesDetailsByEncryptId+encryptAskedQuesId);
			setFtrQuesData(response.data[0]);
			/*const myQuesData = props.myQuesList; 	
			if(myQuesData.length==0){
				history.push('/question/my');
			}
			var selectedQues = await myQuesData.filter(myQuesData => myQuesData.encryptAskedQuesId === encryptAskedQuesId); 
			setFtrQuesData(selectedQues[0]);*/
		}catch(error){
			setErrorMsg(error);
		}
	}
	
	useEffect(()=>{		
		fetchQuesDataFromState();
	},[]);
	
 	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	
	async function scheduleAppointment(){
		if(dt){
			const apptDate = dt;
			const scheApptData = {
				scheAppTime: apptDate,
				askedQuesId: ftrQuesData.askedQuesId
			}
			try{
				const res = await apiCall.create(apiEndPoint.scheduleUserAppointment, scheApptData);
				console.log('res - ',res);
				if(res && res.data.status === "success"){
					//console.log(res.data.roomData);
					toast.success(res.data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000});
					history.push('/question/my');
				}else{
					toast.error(res.data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000});
				}			 
			}catch(error){
				setErrorMsg(error);
			}
		}else{
			setErrorMsg('Please select your time slot!');
		}
 	}
	
	return (		
		<section className="inner_wrapper pb-5">	
			<Helmet>
				<title>{pageSubTitle} - {config.PROJECT_TITLE}</title>
			</Helmet>
			<Container>
				<Row>
					<Col md={12}>						
						<InnerTitle
							subTitle="Checkout"
							titleHeading={pageSubTitle}
						/>
					</Col>
				</Row>	  
		  		<Row>
					<Col md={12}>
						<div className="ques_wrap">
							<Form className="reg_form"  >
								<ul className="ques_list">
									<li className="ques_item"> 
										<label style={{ fontWeight: '600', paddingBottom: '5px'}}>Select Your Time Slot *</label>
										<DatePicker
											value={dt}
											onChange={val => setDt(val)}
										  />  
									</li>
								</ul>
								<div className="btns_grp" style={{ padding: '15px 0'}}>
									<Button type="button" className="btn gold_btn" disabled={scheApptLoader} onClick={() => scheduleAppointment()}>
										{scheApptLoader ? <Spinner animation="border" role="status" size="sm" className="" /> :'Book Now!'}
									</Button>
								</div>
							</Form>
						</div>
					</Col>
				</Row>
           </Container>
		</section>
	);
}

/*const mapStateToProps = (state) => {
	return { myQuesList: state.stMyQues };
}

export default connect(mapStateToProps)(ScheduleAppt);*/
export default ScheduleAppt