import React, { useEffect, useState } from 'react';

const GetProperDate = (props) => {
	//const months = ["January", "February", "March", "April", "May", "June", "July", "August","September", "October", "November", "December"];
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug","Sep", "Oct", "Nov", "Dec"];
	const propDate = Date.parse(props.fDate);	
	
	const hour = new Date(parseInt(propDate)).getHours();
	var suffix = hour >= 12 ? "PM":"AM";
	var hours = ((hour + 11) % 12 + 1);
	
	const eDate = new Date(parseInt(propDate)).getDate()+' '+months[(new Date(parseInt(propDate)).getMonth())]+' '+new Date(parseInt(propDate)).getFullYear()+', '+hours+':'+new Date(parseInt(propDate)).getMinutes()+' '+suffix;
	//{new Date(parseInt(Ques.consultantSchedTime*1000)).toGMTString()}

	return (
		<>{eDate}</>			
	);
};

export default GetProperDate;