import { combineReducers } from 'redux';
//import songsReducer from './songsReducer';
//import selectedSongReducer from './selectedSongReducer';
//import userReducer from './userReducer';
import quesReducer from './quesReducer';
import eventReducer from './eventReducer';
import liveShowReducer from './liveShowReducer';
import cmsReducer from './cmsReducer';
import bannersTSReducer from './bannersTSReducer';
import workerTypeReducer from './workerTypeReducer';

export default combineReducers({
  //songs: songsReducer,
  //selectedSong: selectedSongReducer,
  //stLogInUserDetails: userReducer,
  stMyQues: quesReducer,
  stUpcomingEvents: eventReducer,
  stLiveTalkShowEvent: liveShowReducer,
  stCMSData: cmsReducer,
  stTSbanners: bannersTSReducer,
  stWorkerTypesList: workerTypeReducer
});
