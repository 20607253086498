import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Event from './Event'


export default function Events(props){
	const eventsData = props.upcomingEventsData;
  
    return (
        <Row className="eventList">
			{eventsData.length>0 && eventsData.map((e, i) => (
				<Col md={4} key={i}>
					<Event eInfo={e} lpInd={i} />
				</Col>
			))}
        </Row>
    )
}
