import React,{useEffect,useState} from 'react';
import Helmet from "react-helmet"
import config from '../../helpers/config.json';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
//import { joinRoom } from '../../apis/enablexApi'
import { Col, Container, Row, Badge, BadgeProps, Alert, Button, Spinner } from 'react-bootstrap';
import InnerTitle from '../title/InnerTitle';
import { Link } from 'react-router-dom';
import { BsFillChatQuoteFill } from "react-icons/bs";
import {useDispatch} from 'react-redux';
import {connect} from 'react-redux';
import { fetchMyQuesAction } from '../../actions/quesAction';
import { fetchWorkerTypeAction } from '../../actions/workerTypeAction';
import Setup from '../payments/Setup';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import GetProperDate from '../../helpers/GetProperDate';
import serviceToken from '../../apis/serviceTokens'; 

const MyQues = (props) => {
	 
	toast.configure();
	const logUser = serviceToken.getLoggedInUser();
	const userId = logUser.userId;
	const [errMsg,setErrMsg] = useState(null);
	const [quesLoaded, setQuesLoaded] = useState(false);
	const [roomJoinLoader,setRoomJoinLoader] = useState(false);
	const [myAskedQuesData,setMyAskedQuesData] = useState(props.myQuesList);
	const [masterWorkerTypes,setMasterWorkerTypes] = useState(props.myWorkerTypeMasters);
	
	const dispatch = useDispatch();
 
	async function fetchMyQuesData(){
		try{
			setQuesLoaded(false);
			if(masterWorkerTypes.length===0){
				const mswtRes = await apiCall.get('master/worker-types');
				dispatch(fetchWorkerTypeAction(mswtRes.data));
				setMasterWorkerTypes(mswtRes.data);	
			}
			//if(myAskedQuesData.length===0){
				const response = await apiCall.get(apiEndPoint.fetchUsersAskedQuesList+userId); // Calling Get API for getting all my asked questions
				dispatch(fetchMyQuesAction(response.data));
				setMyAskedQuesData(response.data);
			//}
			setQuesLoaded(true);
		}catch(errMg){
			//console.log('error chk my: ',error)
			setErrMsg(errMg);
			toast.error(errMg, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
			
		}
	}
	
	useEffect(()=>{		
		fetchMyQuesData();
	},[]);
	 
		
	return (
		<section className="inner_wrapper pb-5">	
			<Helmet>
				<title>My Asked Questions - {config.PROJECT_TITLE}</title>
			</Helmet>
			<Container>
				<Row>
					<Col md={12}>						
						<InnerTitle
							subTitle="My Questions"
							titleHeading="Asked Questions"
						/>
					</Col>
				</Row>
				{quesLoaded ? (
				<Row>
					<Col md={12}>
					<div className="ques_wrap">
						{myAskedQuesData.map(Ques => (
						<div className="my_ques" key={Ques.askedQuesId}>
							
							<div className="mq_head">
								<h6>Q. {Ques.quesId==0 ? Ques.otherQuesTitle : Ques.Question.quesTitle}</h6>
								<div className="hqh_rgt">									
									<Badge pill bg="success" className="ques_price">${Ques.quesAmt}</Badge>
								</div>
							</div>
							<div className="mq_body">
								<ul className="mq_status">
									<li><small>Asked Date</small><span>{new Date(parseInt(Ques.askedDate)).getMonth()+1}/{new Date(parseInt(Ques.askedDate)).getDate()}/{new Date(parseInt(Ques.askedDate)).getFullYear()}</span></li>
									<li><small>Payment</small>{Ques.paymentSts==0 ? <Badge className="alert alert-warning">Pending</Badge> : <Badge className="alert alert-success">Completed</Badge>}</li>
									{Ques.paymentSts==1 ?
										<>
											{Ques.consultantSchedSts==1 ? <li><small>Consultant Scheduled Time</small><span><GetProperDate fDate={Ques.consultantSchedTimeStr} /></span></li> : <li><small>Consultant Status</small><Badge className="alert alert-warning">Pending</Badge></li> }
											
											{Ques.workerId>0 ? <li><small>Assigned
											{masterWorkerTypes.filter(masterWorkerTypes => masterWorkerTypes.workerType === Ques.ServiceWorkerDetail.workerType).map(fswt => (
											<>
												{' '+fswt.typeName}
											</>											
											))}</small><span>{Ques.ServiceWorkerDetail.firstName} {Ques.ServiceWorkerDetail.lastName}</span></li> : '' }
										</>
									: 
										<>
										{Ques.quesId==0 ? <><li><small>Request</small>{Ques.ReqActionPerformSts==0 ? <Badge className="alert alert-warning">Pending</Badge> : <Badge className="alert alert-success">Accepted</Badge>}</li></> : '' }
										</>
									}
								</ul>
							</div>
							
								{Ques.paymentSts==0 ?  
									<>{((Ques.quesId==0 && Ques.ReqActionPerformSts==1) || (Ques.quesId>0)) ? <div className="btns_grp"><Link className="btn gold_btn" to={'/payment/question/'+Ques.encryptAskedQuesId}>Pay Now</Link></div> : '' }</>
								:
									<>
									{Ques.solvedSts==1 && Ques.feedbackSts==0 ? <div className="btns_grp"><Link className="btn gold_btn" to={'/drop-your-feedback/'+Ques.askedQuesId} quesData={Ques}><BsFillChatQuoteFill/> Give Feedback</Link></div> : 
										<>
										</>
									}
									</>
								}
							
						</div>
						))}						
					</div>
					</Col>
				</Row>
				) : (
					<Row>
						<Col md={12}>
							<div className="ques_wrap">
								<h5 className="text-center">Please Wait <Spinner animation="border" role="status" size="sm" className="" /></h5>
							</div>
						</Col>
					</Row>
				)}
			</Container>			
		</section>
	);
}

const mapStateToProps = (state) => {
	return { myQuesList: state.stMyQues, myWorkerTypeMasters: state.stWorkerTypesList };
}

export default connect(mapStateToProps)(MyQues);