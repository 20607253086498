import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import Helmet from "react-helmet";
import { Col, Container, Row } from 'react-bootstrap';//,Tabs ,Tab,Table
import { BsQuestionCircle, BsWallet, BsArrowRight } from "react-icons/bs"; //BsPerson, 

import config from '../helpers/config.json';
import apiCall from '../apis/apiService';
import apiEndPoint from '../apis/apiEndPoints';
import serviceToken from '../apis/serviceTokens';
import Events from './events/Events';
//import InnerTitle from './title/InnerTitle';
import { fetchMyQuesAction } from '../actions/quesAction';
import { fetchUpcomingEventsAction } from '../actions/eventAction';

const Dashboard = (props) => {
	
	const dispatch = useDispatch();
	const pageMainTitle = 'Dashboard';
	const logUser = serviceToken.getLoggedInUser();
	const userId = logUser.userId;
	const [myAskedQuesData,setMyAskedQuesData] = useState(props.myQuesList);
	const [upcomingEventsData,setUpcomingEventsData] = useState(props.curUpcomingEvents);
	
	//const [eventPaymentSec, setEventPaymentSec] = useState(false);
	
 	async function fetchMyQuesData(){
		try{			
			if(myAskedQuesData.length===0){
				const response = await apiCall.get(apiEndPoint.fetchUsersAskedQuesList+userId); // Calling Get API for getting all my asked questions
				dispatch(fetchMyQuesAction(response.data));
				setMyAskedQuesData(response.data);
			}
			if(upcomingEventsData.length===0){
				const resEvent = await apiCall.get(apiEndPoint.upcomingEvents+userId); // Calling Get API for getting upcoming events
				setUpcomingEventsData(resEvent.data);
				dispatch(fetchUpcomingEventsAction(resEvent.data));
			}			
		}catch(error){
			console.log('catch error on dashboardJS: ',error);
		}
	}
	
	useEffect(()=>{		
		fetchMyQuesData();	
	},[]);
	
	
	/*if(myAskedQuesData.length>0){
		const getFilteredQuesList = myAskedQuesData.filter(myAskedQuesData => myAskedQuesData.consultantSchedSts === 1);
		setFtrQuesData(getFilteredQuesList);
	}*/
	  
  return (
	<section className="inner_wrapper pb-5">
 		<Container className="py-4">
		<Helmet>
			<title>{pageMainTitle+' - '+config.PROJECT_TITLE}</title>
		</Helmet>
        <Row>
          <Col md={12}>
            <h4>{pageMainTitle}</h4>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Link to="/question/my">
				<div className="te_card my-3 mx-auto shadow-sm">			
				 <div className="dash_card dci_1">
				   <span className="dc_icon"><BsQuestionCircle style={{ fontSize: '20px', fontWeight: '600' }} /></span>
				   <div className="dc_info">
					 <h2>{myAskedQuesData.length}</h2>
					 <small>My Questions</small>
				   </div>
				 </div>			 
				</div>
			</Link>
          </Col>
          <Col md={3}>
            <div className="te_card my-3 mx-auto shadow-sm">
             <div className="dash_card dci_2">
               <span className="dc_icon"><BsWallet style={{ fontSize: '20px' }} /></span>
               <div className="dc_info">
                 <h2><span>$</span>{logUser.walletBal}</h2>
                 <small>My Wallet</small>
               </div>
             </div>
            </div>
          </Col>
          {/*<Col md={3}>
            <div className="te_card my-3 mx-auto shadow-sm">
             <div className="dash_card dci_3">
               <span className="dc_icon"><BsPerson/></span>
               <div className="dc_info">
                 <h2>45</h2>
                 <small>My Profile</small>
               </div>
             </div>
            </div>
          </Col>*/}
          <Col md={3}>
            <div className="te_card my-3 mx-auto shadow-sm">
             <div className="dash_card dash_ask_qus">
                <Link to="/question/asked">Ask Question <span><BsArrowRight/></span></Link>
             </div>
            </div>
          </Col>
        </Row>
	 	<Row>
          <Col md={12} className="mt-3">
             <h3>Upcoming Events</h3>
          </Col>
          <Col md={12}>
            <Events upcomingEventsData={upcomingEventsData} />
          </Col>
        </Row>
        {/*<Row>
          <Col md={12}>
          <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="my-4">
            <Tab eventKey="home" title="Upcoming Events">
              <div className="te_card p-3">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td colSpan="2">Larry the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </Table>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Transactions">
             
            </Tab>
            <Tab eventKey="contact" title="Activity Log">
            Activity Log
            </Tab>
          </Tabs>
          </Col>
        </Row>*/
		}
      </Container>
	 
	</section> 
  );
};


const mapStateToProps = (state) => {
	return { myQuesList: state.stMyQues, curUpcomingEvents: state.stUpcomingEvents };
}

export default connect(mapStateToProps)(Dashboard);