import React from 'react';
import MsoIcon1 from '../../images/mso_icon1.svg';
import MsoIcon2 from '../../images/mso_icon2.svg';
import MsoIcon3 from '../../images/mso_icon3.svg';
import MsoImg from '../../images/mso_img.png';

export default function MultiSerOffer(props) {
    return (
        <div className="mso_wrap">
            <div className="mso_info">
                <h4>{props.msoDetails.secTitle}</h4>
                <div dangerouslySetInnerHTML={{ __html: props.msoDetails.secShortDesc }} />
                <ul className="mso_list">
                    <li>
                        <div className="mso_icon"><img src={MsoIcon1} alt=""/></div>
                        <div className="mso_li_info">
                            <h5>{props.msoDetails.titleTwo}</h5>
                            <p>{props.msoDetails.titleTwoSlug}</p>
                        </div>
                    </li>
                    <li>
                        <div className="mso_icon"><img src={MsoIcon2} alt=""/></div>
                        <div className="mso_li_info">
                            <h5>{props.msoDetails.subTitleOne}</h5>
                            <p>{props.msoDetails.subTitleTwo}</p>
                        </div>
                    </li>
                    <li>
                        <div className="mso_icon"><img src={MsoIcon3} alt=""/></div>
                        <div className="mso_li_info">
                            <h5>{props.msoDetails.shortDescOne}</h5>
                            <p>{props.msoDetails.shortDescTwo}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="mso_img">
                <img src={MsoImg} alt='' />
            </div>
        </div>
    )
}