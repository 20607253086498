import React from 'react';
import Owner from '../../images/eh2.png';

export default function OwnerBio(props) {
    return (
        <div className="ownr_bio bg-white shadow-lg">
           { /*<div className="ob_img"><img src={Owner} alt=""/></div>*/}
            <div className="ob_info">
                <h2>{props.aboutUsDetails.secSubTitle}</h2>
                <div dangerouslySetInnerHTML={{ __html: props.aboutUsDetails.secShortDesc }} />
                <strong className="mt-4 d-block">Registered Electrical Professional Engineer from following states:</strong>
                <ul className="certi_list">
                    <li><span className="state_name">Arizona</span><span className="certi_num">43422</span></li>
                    <li><span className="state_name">California</span><span className="certi_num">20203</span></li>
                    <li><span className="state_name">Idaho</span><span className="certi_num">14332</span></li>
                    <li><span className="state_name">Washington</span><span className="certi_num">41893</span></li>
                </ul>
            </div>
        </div>
    )
}
