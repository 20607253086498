import React from "react";
import Helmet from "react-helmet"
import InnerTitle from '../title/InnerTitle';
import config from '../../helpers/config.json';
import DropIn from "braintree-web-drop-in-react";
import { Link } from 'react-router-dom';
import { BsFillChatQuoteFill } from "react-icons/bs";
import { Col, Container, Row, Badge, Tabs ,Tab,Table, Spinner, Button } from 'react-bootstrap';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import serviceToken from '../../apis/serviceTokens';
import ScheduleAppt from '../questions/ScheduleAppt';
import {connect} from 'react-redux';
//import {toast} from 'react-toastify'; 
//import 'react-toastify/dist/ReactToastify.css';
 
class quesPayment extends React.Component {
	instance;
	//toast.configure();
	state = {
		clientToken: null,
		selectedQuesDetails: [],
		selectedEncryptAskedQuesId: '',
		loaderProceed: false,
		submitLoader: false,
		waitLoader: false,
		pageTitle: 'Question Payment',
		pageSubTitle: 'Checkout',
		stReferredBy: 0,
		stCartWalletAmt: 0,
		stWalletBal: 0,
	};  
	
	async componentDidMount(){	
		this.setState({ waitLoader: true });	
		var logUser = serviceToken.getLoggedInUser();
		const referredBy = logUser.referredBy;
		const walletBal = parseInt(logUser.walletBal);
		const encryptAskedQuesId = this.props.match.params.encryptAskedQuesId;
		const checkoutQuesData = await apiCall.get(apiEndPoint.fetchAskedQuesDetailsByEncryptId+encryptAskedQuesId); // Get Ques Details
		const askQuesAmt = parseInt(checkoutQuesData.data[0].quesAmt);
		if(askQuesAmt>=walletBal){
			var cartAmt = askQuesAmt-walletBal;	
		}else{
			var cartAmt = 0;
		}
		
		
		if(cartAmt>0){
 			const response = await apiCall.get('payments/client_token'); // Get Token from Braintree	
			var clientToken = response.data.clientToken;
 		}else{
			var clientToken = '';
		}
		
		this.setState({ waitLoader: false });
		
		this.setState({ 
			clientToken,
			selectedQuesDetails: checkoutQuesData.data,
			selectedEncryptAskedQuesId: encryptAskedQuesId,
			stReferredBy: referredBy,
			stCartWalletAmt: cartAmt,
			stWalletBal: walletBal
		});
		
		//console.log('stCartWalletAmt: ',this.state.stCartWalletAmt);
		 
	}
	
	async submitNow() {
		try{
			this.setState({ submitLoader: true });
			const logUser = serviceToken.getLoggedInUser();
			const walletBal = parseInt(logUser.walletBal);
			
			var askedQuesId = this.state.selectedQuesDetails[0].askedQuesId;
			var userId = this.state.selectedQuesDetails[0].userId;
			var quesAmt = this.state.selectedQuesDetails[0].quesAmt;
			const submitData = {
				actionForId: askedQuesId,
				userId: userId,
				actionFor: 0,
				quesAmt: quesAmt,
				oldWalletBal: walletBal,
			}	
			const wallRes = await apiCall.create('payments/apply_wallet_asked_ques',submitData);
			if(wallRes && wallRes.data.status === "success"){
				localStorage.setItem('TEuserData',JSON.stringify(wallRes.data.userData));
				this.props.history.push('/question/thankyou');
			}else{
				//setErrMsg(res.data.message);
				//console.log('res error msg pay: ',wallRes.data.message);
				//toast.error(wallRes.data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
				this.setState({ submitLoader: false });
			}
		}catch(errMsg){
			//setErrMsg(error);
			//toast.error(errMsg, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000});
			this.setState({ submitLoader: false });
		}
				
	}
 
	async buy() {
		// Send the nonce to your server
		const { nonce } = await this.instance.requestPaymentMethod();
		
 		/*const reqData = {
			payment_method_nonce:nonce,
			payAmt:this.state.selectedQuesDetails[0].quesAmt
		}*/
		const reqData = {payment_method_nonce:nonce, payAmt:this.state.stCartWalletAmt}
		//console.log('nonce: ',nonce);
		try{
			this.setState({ 
				loaderProceed: true
			});
			const res = await apiCall.create('payments/received',reqData);
			if(res.data.success){
				//console.log('res: ',res.data.transaction);			
				const paymentBy = res.data.transaction.paymentInstrumentType;
				if(paymentBy=='paypal_account'){
					var payerId = res.data.transaction.paypal.payerId;
					var payerStatus = res.data.transaction.paypal.payerStatus;
					var paymentId = res.data.transaction.paypal.paymentId;
					var transactionFeeAmount = res.data.transaction.paypal.transactionFeeAmount;
					var cardLast4 = '';
					var cardType = '';
				}else{
					var cardLast4 = res.data.transaction.paymentReceipt.cardLast4;
					var cardType = res.data.transaction.paymentReceipt.cardType;
					var payerId = '';
					var payerStatus = '';
					var paymentId = '';
					var transactionFeeAmount = '';
				}
				var askedQuesId = this.state.selectedQuesDetails[0].askedQuesId;
				var userId = this.state.selectedQuesDetails[0].userId;
				const paymentData = {
					actionForId: askedQuesId,
					userId: userId,
					referredBy: this.state.stReferredBy,
					actionFor: 0,
					paymentBy: paymentBy,
					merchantAccountId: res.data.transaction.merchantAccountId,
					status: res.data.transaction.status,				
					type: res.data.transaction.type,
					settlementBatchId: res.data.transaction.settlementBatchId,
					txnId: res.data.transaction.paymentReceipt.id,
					txnAmount: res.data.transaction.paymentReceipt.amount,
					currencyIsoCode: res.data.transaction.paymentReceipt.currencyIsoCode,
					globalId: res.data.transaction.paymentReceipt.globalId,
					cardLast4: cardLast4,
					cardType: cardType,
					paypalPayerId: payerId,
					paypalPayerStatus: payerStatus,
					paypalPaymentId: paymentId,
					paypalTransFeeAmount: transactionFeeAmount,
					merchantIdentificationNo: res.data.transaction.paymentReceipt.merchantIdentificationNumber,
					merchantName: res.data.transaction.paymentReceipt.merchantName,
					pinVerified: res.data.transaction.paymentReceipt.pinVerified,
					processingMode: res.data.transaction.paymentReceipt.processingMode,
					processorAuthCode: res.data.transaction.paymentReceipt.processorAuthorizationCode,
					processorResCode: res.data.transaction.paymentReceipt.processorResponseCode,
					processorResText: res.data.transaction.paymentReceipt.processorResponseText,
					terminalIdentificationNo: res.data.transaction.paymentReceipt.terminalIdentificationNumber				
				}
				const payRes = await apiCall.create('payments/success_entry',paymentData);				
				if(payRes && payRes.data.status === "success"){
					//this.props.history.push('/schedule/question/appointment/'+this.state.selectedEncryptAskedQuesId);
					this.props.history.push('/question/thankyou');
				}else{
					//setErrMsg(res.data.message);
					console.log('res error msg pay: ',payRes.data.message);	
				}
	
			}else{
				console.log('res error msg: ',res.data.message);	
			}
		}catch(error){
			//setErrMsg(error);
		}
	}
 
  render() {    
      return (
        <section className="inner_wrapper pb-5 paymentPage">	
			<Helmet>
				<title>Checkout - {config.PROJECT_TITLE}</title>
			</Helmet>
			<Container>
				<Row>
					<Col md={12}>						
						<InnerTitle
							subTitle={this.state.pageTitle}
							titleHeading={this.state.pageSubTitle}
						/>
					</Col>
				</Row>
				{!this.state.waitLoader ? (
					<Row>
						<Col md={12}>
						<div className="ques_wrap">
						{this.state.selectedQuesDetails.map(Ques => (
							<div className="my_ques">
									
								<div className="mq_head">
									<h6>Q. {Ques.quesId==0 ? Ques.otherQuesTitle : Ques.Question.quesTitle}</h6>
									<div className="hqh_rgt">									
										<Badge pill bg="success" className="ques_price">${Ques.quesAmt}</Badge>
									</div>
								</div>
								<div className="mq_body">
									<ul className="mq_status">
										<li><small>Asked Date</small><span>{new Date(parseInt(Ques.askedDate)).getMonth()+1}/{new Date(parseInt(Ques.askedDate)).getDate()}/{new Date(parseInt(Ques.askedDate)).getFullYear()}</span></li>
										{Ques.paymentSts==1 ?
											<>
												<li><small>Consultant Scheduled Time</small><span>{new Date(parseInt(Ques.consultantSchedTime)).toLocaleString('en-US')}</span></li>
												<li><small>Solved Status</small>{Ques.solvedSts==0 ? <Badge className="alert alert-warning">Pending</Badge> : <Badge className="alert alert-success">Completed!</Badge>}</li>
											</>
										: '' }
									</ul>
								
								</div>
								{!this.state.clientToken ?
									 <div className="">
										<h6 className="walNotice">You are paying ${this.state.selectedQuesDetails[0].quesAmt} from wallet! </h6>
									 </div>
								:
								
									<div className="btns_grp">
										<div style={{ width: '100%' }}>			 
											<DropIn
												options={{
													authorization: this.state.clientToken,
													paypal: {
														flow: "vault",
														buttonStyle: {
														  color: 'blue',
														  shape: 'rect',
														  size: 'medium'
														}
													},
												}}
												onInstance={(instance) => (this.instance = instance)}
											/>
										</div>
									</div>
								
								}
								<div className="btns_grp">
									{!this.state.clientToken ?
										<><Button onClick={this.submitNow.bind(this)} disabled={this.state.submitLoader} className="btn gold_btn">{!this.state.submitLoader ? 'Pay Now!' : <>Please Wait <Spinner animation="border" role="status" size="sm"  /></>}</Button>&nbsp;&nbsp;</>
									:
										<><button onClick={this.buy.bind(this)} className="btn gold_btn">{!this.state.loaderProceed ? 'Make Payment' : <>Processing <Spinner animation="border" role="status" size="sm"  /></>}</button>&nbsp;&nbsp;</>
									}
									<Link className="btn gold_btn" to={'/question/my'}>Back</Link>
								</div> 								
							
							</div>
						))}
						</div>
					</Col>
				</Row>
				) : (
					<Row>
						<Col md={12}>
							<div className="ques_wrap">
								<h5 className="text-center">Please Wait <Spinner animation="border" role="status" size="sm" className="" /></h5>
							</div>
						</Col>
					</Row>
				)}

				
           </Container>
		</section>         
      );     
  }
}

/*const mapStateToProps = (state) => {
	return { myQuesList: state.stMyQues };
}

export default connect(mapStateToProps)(quesPayment);*/
export default quesPayment