import React,{useState,useEffect} from 'react';
import Helmet from "react-helmet";
import { Col, Container, Row, Form, Spinner,Button } from 'react-bootstrap';
//import Button from '../../shared/UI/Button';
import config from '../../../helpers/config.json';
import apiCall from '../../../apis/apiService';
import apiEndPoint from '../../../apis/apiEndPoints';
import InnerTitle from '../../title/InnerTitle';
import AlertDismissible from '../../shared/aleartmsg';
import VerifyOtp from './VerifyOtp';
import {useDispatch} from 'react-redux';
import {connect} from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { fetchWorkerTypeAction } from '../../../actions/workerTypeAction';

const schemaemail = yup.object().shape({
	areYou: yup.string().required('Are You......?'),
	firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    contactNo: yup.string().required('Contact number is required').matches(/^([0-9][0-9]*)$/,"Please enter numeric value").min(10,"Enter 10-digit contact number"),
	Email: yup.string().required("Whoops. You didn't enter an email.").email('Please enter a valid email.'),
	Address: yup.string().required('Address is required!'),
	city: yup.string().required('City is required!'),
	state: yup.string().required('State is required!'),
	zipCode: yup.string().required('Zip Code is required!').matches(/^([0-9][0-9]*)$/,"Please enter numeric value"),
});

const ContractorSignup = (props) => {
	  	
	window.scrollTo(0, 0);
	const dispatch = useDispatch();
	const [comPageContent] = useState(props.pageData);
	
	const [loader,setLoader] = useState(false);
	const [aleart,setAleart] = useState(null);
	const [otpSubmit,setOtpSubmit] = useState(false);
	const [userInfo,setUserInfo] = useState([]);
	const [otpNo,setOtpNo] = useState(0);
	const [aleartmsg,setAleartmsg] = useState(null);
	const [btnColor,setBtnColor] = useState(false);
	const [masterWorkerTypes,setMasterWorkerTypes] = useState(props.myWorkerTypeMasters);
	
	const { register, handleSubmit, reset, formState:{ errors } } = useForm({
		resolver: yupResolver(schemaemail)
	});
	
	const emailSubmit = async (data,e) => {
		
		setLoader(true);
		setAleart(null);
		setAleartmsg(null);
		setBtnColor(true);
		
		let userFrmInfo = {
			areYou:data.areYou,
			firstName:data.firstName,
			lastName:data.lastName,
			email:data.Email,
			contactNo:data.contactNo,
			address:data.Address,
			city:data.city,
			state:data.state,
			zipCode:data.zipCode
		}
		setUserInfo(userFrmInfo);		
		try{
			let userEmail = {email:data.Email}
			let res = await apiCall.postPublic(apiEndPoint.verificationCodeSentOnEmail,userEmail);			
			if(res && res.data.status === "success"){				
				await setOtpNo(res.data.sentOtp);
				setOtpSubmit(true);
			}else if(res && res.data.status === "error"){				 
				setAleart(true);
				setAleartmsg({ alearttype:"danger", msg:res.data.message });			
			}
		}catch(err){			 
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:config.ERROR_MSGS.BAD_REQUEST });	     
		}		
		setLoader(false);
		setBtnColor(false);		
	};
	
	async function fetchMasterWTData(){
		try{
			if(masterWorkerTypes.length===0){
				const mswtRes = await apiCall.getPublic('master/worker-types');
				dispatch(fetchWorkerTypeAction(mswtRes.data));
				setMasterWorkerTypes(mswtRes.data);	
			}
		}catch(error){
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:error });	
		}
	}
	
	useEffect(()=>{		
		fetchMasterWTData();
	},[]);
	
	return (
		<>
		{comPageContent.filter(comPageContent => comPageContent.sectionType === 'find-local-pros').map(c => (
		 <section className="inner_wrapper mb-5">
			<Helmet>
				<title>{c.secSubTitle+' - '+config.PROJECT_TITLE}</title>
			</Helmet>
			<Container className="psp">
				<Row>
					<Col>
						<InnerTitle
							subTitle={c.secTitle}
							titleHeading={c.secSubTitle}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="srtDesc">{c.secShortDesc}</p>
					</Col>
				</Row>
				<Row>
					<Col>
					{otpSubmit ? 
						<VerifyOtp userFrmInfo={userInfo} sentOtpNo={otpNo} />
					:
						<Form className="reg_form" onSubmit={handleSubmit(emailSubmit)}> 
							<AlertDismissible showaleart={aleart} aleartmsg={aleartmsg} />
							
							<Form.Group >
							  <Form.Label className="form_label ctrl-lbl">Are You *</Form.Label>
							   <select {...register("areYou")} className="forms_input forms_input_border form-control">
								<option value="">Select...</option>
									{masterWorkerTypes.map(wt => (
										<option value={wt.workerType} key={wt.workerType}>{wt.typeName}</option>
									))}
							  </select>
							  <div className="Err-feedback" >
									<span> {errors.areYou?.message}</span>
							   </div>
							</Form.Group>
  
							<Row className="">
								<Form.Group as={Col} controlId="formGridEmail">
								  <Form.Label className="form_label ctrl-lbl">First Name *</Form.Label>
								   <Form.Control type="text" placeholder="First Name" {...register("firstName")}  className={`forms_input ${errors.firstName ? 'invalid' : 'forms_input_border'}`} />
								   <div className="Err-feedback" >
										<span> {errors.firstName?.message}</span>
								   </div>
								</Form.Group>
							
								<Form.Group as={Col} controlId="formGridPassword">
								  <Form.Label className="form_label ctrl-lbl">Last Name *</Form.Label>
								   <Form.Control type="text" placeholder="Last Name" {...register("lastName")}  className={`forms_input ${errors.lastName ? 'invalid' : 'forms_input_border'}`} />
								   <div className="Err-feedback" >
								   <span>{errors.lastName?.message}</span>
								   </div>
								</Form.Group>
							  </Row> 							 
							
							<Form.Group className="" controlId="formBasicEmail">
								<Form.Label className="form_label ctrl-lbl">Email *</Form.Label>
								<Form.Control type="text" placeholder="Email" {...register("Email")} className={`forms_input ${errors.Email ? 'invalid' : 'forms_input_border'}`} />		
								<div className="Err-feedback" >
								   <span>{errors.Email?.message}</span>
								</div>
							</Form.Group>
						
							<Form.Group className="" controlId="formBasicNumber">
							   <Form.Label className="form_label ctrl-lbl">Contact # *</Form.Label>
							   <Form.Control type="text" placeholder="Contact #" {...register("contactNo")}  className={`forms_input ${errors.contactNo ? 'invalid' : 'forms_input_border'}`} />
							   <div className="Err-feedback" >
							   <span>{errors.contactNo?.message}</span>
							   </div>
						   </Form.Group>
						   
						   <Form.Group className="" controlId="formBasicNumber">
							   <Form.Label className="form_label ctrl-lbl">Address *</Form.Label>
							   <Form.Control type="text" placeholder="Address" {...register("Address")}  className={`forms_input ${errors.Address ? 'invalid' : 'forms_input_border'}`} />
							   <div className="Err-feedback" >
							   <span>{errors.Address?.message}</span>
							   </div>
						   </Form.Group>
						   
						   <Row className="">
								<Form.Group as={Col} controlId="formGridEmail">
								  <Form.Label className="form_label ctrl-lbl">City *</Form.Label>
								   <Form.Control type="text" placeholder="City" {...register("city")}  className={`forms_input ${errors.city ? 'invalid' : 'forms_input_border'}`} />
								   <div className="Err-feedback" >
										<span> {errors.city?.message}</span>
								   </div>
								</Form.Group>
							
								<Form.Group as={Col} controlId="formGridPassword">
								  <Form.Label className="form_label ctrl-lbl">State *</Form.Label>
								   <Form.Control type="text" placeholder="State" {...register("state")}  className={`forms_input ${errors.state ? 'invalid' : 'forms_input_border'}`} />
								   <div className="Err-feedback" >
								   <span>{errors.state?.message}</span>
								   </div>
								</Form.Group>
								
								<Form.Group as={Col} controlId="formGridPassword">
								  <Form.Label className="form_label ctrl-lbl">Zip Code *</Form.Label>
								   <Form.Control type="text" placeholder="Zip Code" {...register("zipCode")}  className={`forms_input ${errors.zipCode ? 'invalid' : 'forms_input_border'}`} />
								   <div className="Err-feedback" >
								   <span>{errors.zipCode?.message}</span>
								   </div>
								</Form.Group>
							  </Row>
							
							<Button type="submit" variant="" className="btn gold_btn" disabledSts={loader} >
								{loader ? <Spinner animation="border" role="status" size="sm" className="m-2" />:'Create Now!'}
							</Button>
						</Form>
					}
					</Col>
				</Row>				
			</Container>
		</section>
		))}
		</>
	);
}

const mapStateToProps = (state) => {
	return { myWorkerTypeMasters: state.stWorkerTypesList };
}

export default connect(mapStateToProps)(ContractorSignup);