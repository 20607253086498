import React,{useState} from 'react';
import Helmet from "react-helmet";
import config from '../../helpers/config.json';
import InnerTitle from '../../components/title/InnerTitle';
import { Col, Container, Row } from 'react-bootstrap';
  

const ComPage = (props) => {
	
	window.scrollTo(0, 0);
	const pageSlug = props.pageSlug;
	const [comPageContent] = useState(props.pageData);
	
	return (
		<>
		{comPageContent.filter(comPageContent => comPageContent.pageName === pageSlug).map(c => (
			<section key={c.cmsId} className="inner_wrapper pb-5 comPage">
				<Helmet>
					<title>{c.secTitle+' - '+config.PROJECT_TITLE}</title>
				</Helmet>
				<Container className="comPageCon">
					<Row>
						<Col md={12}>						
							<InnerTitle
								subTitle=""
								titleHeading={c.secTitle}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="comPageInfo">						
							<div dangerouslySetInnerHTML={{ __html: c.contentDescOne }} />
						</Col>
					</Row>
				</Container>			
			</section> 
		))}
		</>
	);
};

export default ComPage