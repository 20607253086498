import React,{useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import {useDispatch} from 'react-redux';
import {connect} from 'react-redux';
import InnerTitle from '../title/InnerTitle';
import config from '../../helpers/config.json';
import apiCall from '../../apis/apiService';
import apiEndPoint from '../../apis/apiEndPoints';
import { fetchWorkerTypeAction } from '../../actions/workerTypeAction';
import { Col, Container, Row, Form, Button, Spinner } from 'react-bootstrap';
import {FaMapMarkerAlt, FaUser} from 'react-icons/fa';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schemaemail = yup.object().shape({
	areYou: yup.string().required('Are You......?'),
});

const FindLocal = (props) => {
	
	window.scrollTo(0, 0);
	const dispatch = useDispatch();
	const [aleart,setAleart] = useState(null);
	const [aleartmsg,setAleartmsg] = useState(null);
	const [loader,setLoader] = useState(false);
	const [btnColor,setBtnColor] = useState(false);
	const [loaderChk, setLoaderChk] = useState(false);
	const [masterWorkerTypes,setMasterWorkerTypes] = useState(props.myWorkerTypeMasters);
	const [filteredProData,setFilteredProData] = useState([]);
	
	const { register, handleSubmit, reset, formState:{ errors } } = useForm({
		resolver: yupResolver(schemaemail)
	});
	
	async function fetchProfessionalsData(){
		try{
			setLoaderChk(false);
			const res = await apiCall.getPublic(apiEndPoint.findLocalPros);
			setFilteredProData(res.data);
			if(masterWorkerTypes.length===0){
				const mswtRes = await apiCall.getPublic('master/worker-types');
				dispatch(fetchWorkerTypeAction(mswtRes.data));
				setMasterWorkerTypes(mswtRes.data);	
			}
			setLoaderChk(true);
		}catch(error){
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:error });	
		}
	}
	
	useEffect(()=>{		
		fetchProfessionalsData();
	},[]);
	
	const frmSubmit = async (data,e) => {
		
		setLoader(true);
		setAleart(null);
		setAleartmsg(null);
		setBtnColor(true);
		 	
		try{
			if(data.areYou=='all' && data.zipCode==''){
				var res = await apiCall.getPublic(apiEndPoint.findLocalPros);
			}else{
				const formData = {areYou:data.areYou, zipCode:data.zipCode}
				var res = await apiCall.postPublic(apiEndPoint.searchProviders,formData);			
			}
			setFilteredProData(res.data);
		}catch(err){			 
			setAleart(true);
			setAleartmsg({ alearttype:"danger", msg:config.ERROR_MSGS.BAD_REQUEST });	     
		}		
		setLoader(false);
		setBtnColor(false);		
	};
	
	return (
		<section className="inner_wrapper mb-5">
			<Helmet>
				<title>Find Local Professionals</title>
			</Helmet>
			<Container>
				<Row>
					<Col>
						<InnerTitle
							subTitle="Find The Best"
							titleHeading="Local Pro's"
						/>
					</Col>
				</Row>	
				
                <Form className='pro_filter' onSubmit={handleSubmit(frmSubmit)}>
                    <Row>
                        <Col>							
							<Form.Group> 
							   <select {...register("areYou")} className="forms_input forms_input_border form-control">
								<option value="all">All Local Pro's</option>
									{masterWorkerTypes.map(wt => (
										<option value={wt.workerType} key={wt.workerType}>{wt.typeName}</option>
									))}
							  </select>
							  <div className="Err-feedback" >
									<span> {errors.areYou?.message}</span>
							   </div>
							</Form.Group>
                        </Col> 
						<Col>							
							<Form.Group as={Col} controlId="formGridEmail">
							   <Form.Control type="text" placeholder="Zip Code" {...register("zipCode")}  className={`forms_input ${errors.zipCode ? 'invalid' : 'forms_input_border'}`} />
							   <div className="Err-feedback" >
							   	<span>{errors.zipCode?.message}</span>
							   </div>
							</Form.Group>
                        </Col>
                        <Col>
                            <Button type="submit" variant="" className="btn gold_btn" disabledSts={loader} >
								{loader ? <Spinner animation="border" role="status" size="sm" className="" />:'Search'}
							</Button>
							
							
                        </Col>
                    </Row>
                </Form>	
				{loaderChk ? (
                <>
				<Row className='mt-5 '>
					{filteredProData.map(lp => (
                    <Col md={4} key={lp.workerId}>
                        <div className='pro_list' key={lp.workerId}>
                            <div className='pro_item_box'>
                                <div className='pib_img'><FaUser/></div>
                                <div className='pib_info'>
                                    <span className='type_of'>{lp.SWTD.typeName}</span>
                                    <h5 className='pro_name'>{lp.firstName} {lp.lastName}</h5>
                                    <p className='pro_address'><FaMapMarkerAlt/> {lp.city} - {lp.zipCode}, {lp.state}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
					))}
                    
                </Row>
				<Row>
                    <Col>
                        <div className='become_pro'>
                            <div>
                            <h4>Want to join Pro Team?</h4>
                            <p>Please Register to become Pro member.</p>
                            </div>
                            <Link to="/contractor/create/account" className='btn gold_btn'>Register Now!</Link>
                        </div>
                    </Col>
                </Row>
				</>
				) : (
					<Row>
						<Col md={12}>
							<div className="ques_wrap">
								<h5 className="text-center">Searching <Spinner animation="border" role="status" size="sm" className="" /></h5>
							</div>
						</Col>
					</Row>
				)}
			</Container>
		</section>
	);
}

const mapStateToProps = (state) => {
	return { myWorkerTypeMasters: state.stWorkerTypesList };
}

export default connect(mapStateToProps)(FindLocal);